import Link from 'next/link';
import CallIcon from '@/components/icons/CallIcon';
import HomeIcon from '@/components/icons/HomeIcon';
// import OfferIcon from '@/components/icons/OfferIcon';
import CategoryIcon from '@/components/icons/CategoryIcon';
import ShoppingCart from '@/components/icons/ShoppingCart';
import { usePathname } from 'next/navigation';
import { useCart } from '@/context/cart';
import useClient from '@/hooks/global/useClient';
import Image from '@/components/global/image';
import CookieConsent from '@/components/CookieConsent';

const FixedBox = ({ t }) => {
  const pathname = usePathname();
  const {
    cart: { cartItems },
  } = useCart();
  const { isClient } = useClient();
  return (
    isClient && (
      <div className='fixed bottom-0 left-0 z-[200] flex w-screen flex-col items-center justify-between bg-white px-3 py-2 md:hidden'>
        <div className='flex w-full items-center justify-between px-3 pb-2'>
          <Link
            href='/'
            className={`flex flex-col items-center justify-center gap-0.5 ${pathname === '/' ? 'text-black' : 'text-zinc-500'}`}
          >
            <HomeIcon />
            <p className='text-sm font-semibold sm:text-base'>{t('Home')}</p>
          </Link>
          <Link
            href='/categories'
            className={`flex flex-col items-center justify-center gap-0.5 ${pathname === '/categories' ? 'text-black' : 'text-zinc-500'}`}
          >
            <CategoryIcon />
            <p className='text-sm font-semibold sm:text-base'>
              {t('Categories')}
            </p>
          </Link>
          <Link
            href='/cart'
            className={`relative bottom-1 flex size-10 items-center justify-center rounded-full border ${pathname === '/cart' ? 'border-black' : 'border-zinc-500 text-zinc-500'} sm:size-12`}
          >
            <ShoppingCart className='size-6' />
            {cartItems?.length > 0 && (
              <p className='absolute -top-2 left-1/2 grid size-4 translate-y-1/2 place-items-center rounded-full bg-black text-xs text-white sm:-top-1'>
                {cartItems?.length}
              </p>
            )}
          </Link>
          <Link
            href='/mega-offers-c-2120'
            className={`flex flex-col items-center justify-center gap-0.5 ${pathname === '/mega-offers-c-2120' ? 'text-black' : 'text-zinc-500'}`}
          >
            {/* <OfferIcon /> */}
              <Image
                src={'/imgs/layout/Discount.gif'}
                className='size-6'
                alt='offers icon'
              />
            <p className='text-sm font-semibold sm:text-base'>{t('Offers')}</p>
          </Link>
          <Link
            href='/static/contact-us'
            className={`flex flex-col items-center justify-center gap-0.5 ${pathname === '/contact' ? 'text-black' : 'text-zinc-500'}`}
          >
            <CallIcon className='h-6 w-5' />
            <p className='text-sm font-semibold sm:text-base'>{t('Contact')}</p>
          </Link>
        </div>
        <CookieConsent className='flex md:hidden' />
      </div>
    )
  );
};

export default FixedBox;
