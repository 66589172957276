import LocaleSwitcher from '@/components/global/buttons/LocaleSwitcher';
import Arrow from '@/components/icons/Arrow';
import CurrencyIcon from '@/components/icons/CurrencyIcon';
// import Global from '@/components/icons/Global';
import Image from '@/components/Image';
import { useCart } from '@/context/cart';
import { useCheckout } from '@/context/checkout';
import userStore from '@/hooks/useStore';
import { handleResetStorage } from '@/utils/global';
import { getCookie } from 'cookies-next';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

const Header = ({ t }) => {
  const { dispatch: dispatchCart } = useCart();
  const { dispatch: dispatchCheckout } = useCheckout();
  const amount = getCookie('shipping_free_limit') || '449';
  const router = useRouter();
  const { locale } = router;
  const store = userStore(locale);

  const countries = [
    {
      name: { ar: 'السعودية', en: 'Saudi Arabia' },
      icon: '/imgs/layout/countries/sa.svg',
      store: 'ksa',
    },
    {
      name: { ar: 'الإمارات', en: 'UAE' },
      icon: '/imgs/layout/countries/uae.svg',
      store: 'uae',
    },
    {
      name: { ar: 'الكويت', en: 'Kuwait' },
      icon: '/imgs/layout/countries/kuwait.svg',
      store: 'ksa',
    },
    {
      name: { ar: 'البحرين', en: 'Bahrain' },
      icon: '/imgs/layout/countries/bahrain.svg',
      store: 'ksa',
    },
    {
      name: { ar: 'عمان', en: 'Oman' },
      icon: '/imgs/layout/countries/oman.svg',
      store: 'ksa',
    },
    {
      name: { ar: 'قطر', en: 'Qatar' },
      icon: '/imgs/layout/countries/qatar.svg',
      store: 'ksa',
    },
  ];
  const getCountryName = (country, locale) => {
    const language = locale.includes('ar') ? 'ar' : 'en';
    return country.name[language] || country.name.en; // Fallback to English if locale is not found
  };

  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.store === store)
  );
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    const currentLocale =
      country.store === 'ksa'
        ? locale?.includes('ar')
          ? 'ar'
          : 'en'
        : locale?.includes('ar')
          ? 'ar-ae'
          : 'en-ae';
    if (selectedCountry?.store !== country?.store) {
      handleResetStorage(router, signOut, currentLocale);
      dispatchCart({ type: 'clear' });
      dispatchCheckout({ type: 'clear' });
    }
  };

  return (
    <header className='bg-black text-white md:py-3'>
      <div className='mx-auto hidden w-11/12 max-w-[1440px] items-center justify-between py-1 text-sm md:flex'>
        <p
          suppressHydrationWarning
          className='flex items-center gap-2 text-center'
        >
          {t('topHeaderMsg', { amount })}{' '}
          {store === 'ksa' ? <CurrencyIcon className='size-3.5' /> : null}
          {t(', does not include large home appliances.')}
        </p>
        <div className='flex items-center justify-center gap-2'>
          <div className='group relative border-e pe-4'>
            <div className='flex cursor-pointer items-center gap-2'>
              <Image
                loading='lazy'
                src={selectedCountry.icon}
                className='h-[17px] w-[26px] object-contain'
                alt={selectedCountry.name.en + ' Flag'}
              />
              <span className='mb-0.5'>
                {getCountryName(selectedCountry, locale)}
              </span>
              <Arrow />
            </div>
            <div className='absolute start-0 top-full z-[100] hidden min-w-36 max-w-36 rounded-md bg-white p-2 text-black shadow-lg group-hover:block'>
              {countries.map((country) => (
                <span
                  key={country.name.en}
                  onClick={() => handleCountryChange(country)}
                  className='flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-200'
                >
                  <Image
                    loading='lazy'
                    src={country.icon}
                    className='h-[17px] w-[26px] object-contain'
                    alt={country.name.en + ' flag'}
                  />
                  <span className='mb-0.5'>
                    {getCountryName(country, locale)}
                  </span>
                </span>
              ))}
            </div>
          </div>
          <Link href='/branches' className='border-e pe-5 ps-2'>
            {t('Branches')}
          </Link>
          <Link href='/static/contact-us' className='pe-5 ps-2'>
            {t('Contact Us')}
          </Link>
          {/* TODO  remove false to return locale switcher*/}
          {/* {false && (
            <p className='flex items-center gap-2 ps-2'>
              <Global />
              <LocaleSwitcher />
            </p>
          )} */}
          <LocaleSwitcher />
        </div>
      </div>
      <p className='hidden text-center uppercase max-sm:text-sm'>
        {t('Discount on Tabby')}
      </p>
    </header>
  );
};

export default Header;
