/*
===============================|> AUTH APIS <|===============================
01 |--> Login Api
02 |--> Register Api
03 |--> reset password:
|--> send email 
|--> send otp api and verify otp 
|--> change password
===============================|> AUTH APIS <|===============================
*/

import axios from '@/utils/axios-config';
// 01 --> Login api
const logIn = async (handshake, username, password) => {
  const data = JSON.stringify({ username, password });
  const config = {
    method: 'post',
    url: '/customer/login',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  console.log('login config', config);
  try {
    const req = await axios(config);
    console.log('login success', req);
    return req;
  } catch (error) {
    return error;
  }
};

// 02 --> Register api
const register = async (
  handshake,
  { email, firstname, lastname, password, mobile, registerType, token: recaptchaToken }
) => {
  const data = JSON.stringify({
    email,
    firstname,
    lastname,
    password,
    mobile,
    profile_picture: 'unknown',
    dateOfBirth: '2001-01-01',
    gender: '1',
    registerType,
  });
  const config = {
    method: 'post',
    url: '/customer/register/account/create',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next'
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 03 --> reset password: 3--> verify otp and change new password api
const resetNewPassword = async ({
  handshake,
  email,
  newPassword,
  confirmPassword,
  resetToken,
}) => {
  const data = JSON.stringify({
    email,
    newPassword,
    confirmPassword,
    resetToken,
  });
  const config = {
    method: 'post',
    url: '/mstore/forget-password',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const resetNewPasswordByMobile = async ({
  handshake,
  mobilenumber,
  password,
}) => {
  const data = JSON.stringify({
    mobilenumber,
    password,
  });
  const config = {
    method: 'post',
    url: '/customer/forget/resetpassword',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const forgetSendOtpForEmail = async (handshake, email) => {
  const data = JSON.stringify({
    email,
  });
  const config = {
    method: 'post',
    url: '/mstore/emailResetToken',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const forgetSendOtpForMobile = async (handshake, mobilenumber, locale) => {
  const data = JSON.stringify({
    mobilenumber: `00${locale.toLowerCase()?.includes('ar') ? '971' : '966'}${mobilenumber?.slice(-9)}`,
    otptype: 'forgot',
    email: '',
    resendotp: '',
    oldmobile: '',
  });
  const config = {
    method: 'post',
    url: '/customer/forget/otp/send',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 05 --> Register By Mobile api
const registerByMobile = async (handshake, mobilenumber, email, recaptchaToken) => {
  function replaceNumberPrefix(number) {
    return number.replace(/^(00966|00971)05/, (match, group) => {
      if (group === '00966') return '009665';
      if (group === '00971') return '009715';
      return match; // Default case, though this won't be hit due to regex structure
    });
  }

  const data = JSON.stringify({
    mobilenumber: replaceNumberPrefix(mobilenumber),
    otptype: 'register',
    email,
    resendotp: '',
    oldmobile: '',
  });

  const config = {
    method: 'post',
    url: '/customer/register/otp/send',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next',
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const loginByMobile = async (handshake, mobilenumber, locale, recaptchaToken) => {
  const data = JSON.stringify({
    mobilenumber: `00${locale.toLowerCase()?.includes('ae') ? '971' : '966'}${mobilenumber?.slice(-9)}`,
    otptype: 'login',
    email: '',
    resendotp: '',
    oldmobile: '',
  });

  const config = {
    method: 'post',
    url: '/customer/login/otp/send',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next'
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const loginByEmail = async (handshake, email, recaptchaToken) => {
  const data = JSON.stringify({
    mobilenumber: '',
    otptype: 'login',
    email,
    resendotp: '',
    oldmobile: '',
  });
  const config = {
    method: 'post',
    url: '/customer/login/otp/send',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next'
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const verifyOTPByEmail = async (handshake, email, otp, recaptchaToken) => {
  const data = JSON.stringify({
    mobilenumber: '',
    email,
    otpcode: otp,
    otptype: 'login',
    oldmobile: '',
  });

  const config = {
    method: 'post',
    url: '/customer/login/otp/verify',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next',
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const loginVerifyOtpByMobile = async (handshake, mobilenumber, otp, locale, recaptchaToken) => {
  const data = JSON.stringify({
    mobilenumber: `00${locale.toLowerCase()?.includes('ae') ? '971' : '966'}${mobilenumber?.slice(-9)}`,
    otpcode: otp,
    otptype: 'login',
    oldmobile: '',
  });
  const config = {
    method: 'post',
    url: '/customer/login/otp/verify',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next'
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

const forgetPasswordVerifyOtpByMobile = async (
  handshake,
  mobilenumber,
  otp,
  locale
) => {
  const data = JSON.stringify({
    mobilenumber: `00${locale.toLowerCase()?.includes('ae') ? '971' : '966'}${mobilenumber?.slice(-9)}`,
    otpcode: otp,
    otptype: 'forgot',
    email: '',
    oldmobile: '',
  });

  const config = {
    method: 'post',
    url: '/customer/forget/otp/verify',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 06 --> Register By Mobile api
const registerVerifyOtpByMobile = async (
  handshake,
  mobilenumber,
  email,
  otp, recaptchaToken
) => {
  const data = JSON.stringify({
    mobilenumber,
    otpcode: otp,
    otptype: 'register',
    oldmobile: '',
    email,
  });
  const config = {
    method: 'post',
    url: '/customer/register/otp/verify',
    headers: {
      Authorization: `Bearer ${handshake}`,
      woocommerce: `Bearer ${recaptchaToken}`,
      appname:'Bearer next'
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

export {
  logIn,
  register,
  resetNewPassword,
  resetNewPasswordByMobile,
  forgetSendOtpForEmail,
  forgetSendOtpForMobile,
  registerByMobile,
  loginByMobile,
  loginByEmail,
  verifyOTPByEmail,
  loginVerifyOtpByMobile,
  forgetPasswordVerifyOtpByMobile,
  registerVerifyOtpByMobile,
};
