import axiosRequest from '@/utils/axios-config';
import { decrypt, encrypt } from '@/utils/crypto';
import { decodeJwt } from '@/utils/global';
import axios from 'axios';
import { setCookie } from 'cookies-next';

export const handShakeV2 = async (locale, req, res) => {
  const { store } = getStore(locale);
  const config = {
    method: 'post',
    url: '/handshake/v2',
    headers: { appname: 'Bearer next' },
    data: {
      store,
    },
  };
  try {
    const reqToken = await axiosRequest(config);
    const token = reqToken?.data?.token;
    saveCookie(token, req, res);
    return reqToken;
  } catch (error) {
    return error;
  }
};

function saveCookie(token, req, res) {
  const isServer = req && res;
  if (!token) return;
  const payload = parseTokenPayload(token, isServer);
  if (!payload) return;
  const options = {
    expires: payload?.exp,
    // httpOnly: true,
    // secure: process.env.NODE_ENV === 'production',
    // sameSite: 'strict',
    // path: '/',
  };
  if (isServer) {
    setCookie('handshake', token, {
      req,
      res,
      ...options,
    });
  } else {
    setCookie('handshake', token, {
      ...options,
    });
  }
}
function getStore(locale) {
  const is_uae_store = locale?.toLowerCase()?.includes('ae');
  const store = is_uae_store
    ? 'uae_'.concat(locale?.toLowerCase()?.slice(0, 2))
    : locale;
  return {
    region: is_uae_store ? 'ae' : 'sa',
    locale: locale?.includes('en') ? 'en' : 'ar',
    store,
  };
}

function parseTokenPayload(token, isServer) {
  const payload = decodeJwt(token, isServer);

  if (!(payload?.exp && payload?.storeName)) return null;

  return {
    storeName: payload?.storeName,
    exp: tokenExp(payload?.exp),
  };
}

function tokenExp(exp) {
  if (exp) return new Date(exp * 1000);
  const now = new Date();
  return new Date(now.getTime() + 5 * 60 * 1000);
}

function isTokenValid(token, locale, isServer) {
  const payload = parseTokenPayload(token, isServer);

  if (!payload) return false;
  const now = new Date();
  const isExpired = now > payload.exp;
  if (isExpired) return false;
  const store = payload?.storeName;
  const isLocaleValid = getStore(locale)?.store === store;
  if (!isLocaleValid) return false;
  return true;
}

export const getSSRHandshake = async (locale, req, res) => {
  const { handshake: token } = req.cookies;
  if (isTokenValid(token, locale, true)) return token;
  return (await handShakeV2(locale, req, res))?.data?.token;
};

export const gethandshakeApiRoute = async (locale) => {
  const data = encrypt({
    locale,
    key: 'gabr'
  })
  const config = {
    method: 'POST',
    url: '/qahst',
    data:{data}
  };
  console.log('first handshake ------------>', data, config)
  try {
    const req = await axios(config)
    console.log('handshake clientside ------------>', req?.data)
    const token = decrypt(req?.data)
    saveCookie(token, null, null)
    return token
  } catch (error) {
    return null
  }
}