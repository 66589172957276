import Body from '@/components/category/Body';
import EmptyComp from '@/components/global/EmptyComp';
import useSearchBox from '@/hooks/search/useSearchBox';
import Loading from '@/components/global/Loading';
import { useState } from 'react';

const SearchBx = ({
  ourSearchContainer,
  ourSearchInput,
  ourSearchButton,
  language,
  t,
  ratings,
  setInputValue,
  inputValue,
}) => {
  const [loadingSearch, setLoadingSearch] = useState(true);

  const {
    allProducts,
    handleSorting,
    totalProducts,
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    ref,
    filter,
    setFilter,
    suggestedProducts,
  } = useSearchBox(ourSearchContainer, ourSearchInput, ourSearchButton, inputValue, setInputValue, setLoadingSearch);
  
  return loadingSearch ? (
    <Loading />
  ) : allProducts?.length ? (
    <Body
      {...{
        setInputValue,
        fromCategory: false,
        language,
        t,
        allProducts,
        ratings,
        handleSorting,
        totalProducts,
        selectedFilterKeys,
        setSelectedFilterKeys,
        handleFilter,
        handleResetFilter,
        allFilters,
        ref,
        filter,
        setFilter,
        currentCategory: t('Search'),
        suggestedProducts,
      }}
    />
  ) : (
    <EmptyComp
      {...{
        title: t('empty search results!'),
        description: t('try another keyword!'),
      }}
    />
  );
};

export default SearchBx;
