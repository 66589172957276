import ShoppingCart from '@/components/icons/ShoppingCart';

import { useContext, useState } from 'react';
import { CartModalContext } from '@/layout';
import { handleAddItemToCart } from '@/utils/product/addToCartFunc';
import { useCart } from '@/context/cart';
import { useRouter } from 'next/router';
import useClient from '@/hooks/global/useClient';
import { retainKeys } from '@/utils/product';
// import { event_add_to_cart } from '@/utils/gtm';

const AddToCart = ({
  fromPDP,
  product,
  qty = 1,
  sku,
  categoryName,
  categoryID,
  setCart = null,
  relatedProducts,
}) => {
  const Context = useContext(CartModalContext);
  const [loading, setLoading] = useState(false);
  const { push, locale } = useRouter();
  const { isClient } = useClient();
  const { dispatch } = useCart();
  // Temporarily removing shapes until handled from backend
  let selected = product?.selectedConfigurations;
  delete selected?.shapes;
  const configurations =
    isClient &&
    Object.values(retainKeys(selected, ['attribute_id', 'option_id']));
  const handleAddToCart = (type) => {
    setLoading(true);
    handleAddItemToCart(
      sku ? { ...product, sku } : product,
      qty,
      setCart,
      setLoading,
      dispatch,
      configurations,
      Context,
      locale,
      categoryName,
      categoryID,
      relatedProducts,
      type,
      push
    ).finally(() => setLoading(false));
  };

  return (
    <button
      onClick={handleAddToCart}
      disabled={loading || !product?.stock?.is_in_stock}
      className={`flex w-full items-center justify-center gap-2 rounded-md bg-black hover:bg-[#E11F27] ${!fromPDP ? 'p-2' : 'rounded-none  p-[14px] max-md:rounded-md max-md:p-2'} text-white disabled:cursor-not-allowed`}
    >
      <>
        {loading ? (
          <span className='size-4 animate-spin rounded-full border-r border-t border-white font-bold' />
        ) : (
          !fromPDP && <ShoppingCart className='size-3 sm:size-4' />
        )}

        <span className='text-xs font-bold sm:text-sm md:text-[14px]'>
          {product?.stock?.is_in_stock
            ? locale?.includes('en')
              ? 'Add To Cart'
              : 'أضف للسلة'
            : locale?.includes('en')
              ? 'Available in stores'
              : 'متوفر في المعارض'}
        </span>
      </>
    </button>
  );
};

export default AddToCart;
