import AWSPersonalizeCtx from '@/context/aws-personalize/aws-personalize-context';
import {
  forgetPasswordVerifyOtpByMobile,
  loginVerifyOtpByMobile,
  registerVerifyOtpByMobile,
  verifyOTPByEmail,
} from '@/services/auth';
import { decodeJwt, handleTime } from '@/utils/global';
import {
  event_global_variables,
  event_login,
  event_otp_verification,
} from '@/utils/gtm';
import { otpValidation } from '@/utils/valitation/auth';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import TimerCounter from './TimerCounter';
import TurnstileRecaptcha from '@/components/turnstile';
import { useTurnstile } from 'react-turnstile';

const Otp = ({ t, setAuth, isFromLogin = false, setIsFromLogin }) => {
  const { locale, asPath, push } = useRouter();
  const turnstile = useTurnstile()
  const [loading, setLoading] = useState(false);
  const handshake = getCookie('handshake');
  const { firstActionAfterLoginHandler } = useContext(AWSPersonalizeCtx);

  const pushToCheckoutHandler = useCallback(() => {
    asPath === '/cart' ? push('/checkout') : undefined;
  }, [asPath, push]);

  const handleOTP = async ({ otp, token }, { resetForm, setFieldError, setFieldValue }) => {
    if (isFromLogin) {
      setLoading(true);
      if (isFromLogin == 'by email') {
        const email = getCookie('email') ? getCookie('email') : null;
        const { data, error } = await verifyOTPByEmail(handshake, email, otp, token);
        setLoading(false);

        if (error) {
          event_otp_verification('Verification Failed');
          setFieldError('otp', t(error?.error || error?.message || 'Verification Failed'));
          turnstile?.reset();
           setFieldValue('recaptcha', false);
           setFieldValue('token', '');
          // toast.error(t(error?.error || 'Verification Failed'));

          const dataInfo = {
            method: 'Email',
            result: `Login Failed: ${error}`,
            user_id: '',
          };

          const { method, result, user_id } = dataInfo;

          event_login(method, result, user_id);
        } else {
          const mage = data?.token;
          const userData = data?.user;
          userData?.addresses && delete userData?.addresses;

          const login = await signIn('credentials', {
            user: JSON.stringify({
              ...userData,
              register_type: data?.register_type,
            }),
            token: mage,
            callbackUrl: '/',
            redirect: false,
          });

          if (login.ok) {
            firstActionAfterLoginHandler();
            const session = await getSession();
            event_global_variables(
              locale,
              session?.user?.id,
              session?.user?.email,
              session?.user?.custom_attributes
                ?.find((item) => item?.attribute_code == 'mobilenumber')
                ?.value?.slice(2),
              session?.user?.city && session?.user?.city
            );
            event_otp_verification(`Verification Success`);
            toast.success(
              locale == 'en'
                ? 'User Logged In SuccesFully'
                : ' تم تسجيل الدخول بنجاح'
            );
            resetForm();
            const data = {
              method: 'Email',
              result: 'Login Success',
              user_id: session?.user?.id,
              email: session?.user?.email,
              phone: session?.user?.custom_attributes
                ?.find((item) => item?.attribute_code == 'mobilenumber')
                ?.value?.slice(2),
              first_name: session?.user?.firstname,
              last_name: session?.user?.lastname,
            };
            const {
              method,
              result,
              user_id,
              email,
              phone,
              first_name,
              last_name,
            } = data;

            event_login(
              method,
              result,
              user_id,
              email,
              phone,
              first_name,
              last_name,
              handleTime(),
              null,
              handleTime()
            );

            const decodeMage = decodeJwt(mage);
            setCookie('mage', mage, {
              expires: new Date(decodeMage?.exp * 1000),
            });
            deleteCookie('username');
            deleteCookie('mail');
            deleteCookie('mobilenumber');

            setCookie('email', null);
            pushToCheckoutHandler();
            setAuth(null);
            setIsFromLogin(false);
            return;
          }

          const dataInfo = {
            method: 'Email',
            result: `Login Failed: ${login?.error}`,
            user_id: '',
          };
          console.log('data in login', data);
          const { method, result, user_id } = dataInfo;

          event_login(method, result, user_id);
        }
        // const resetPassword = getCookie('resetPassword')
        //   ? JSON.parse(getCookie('resetPassword'))
        //   : null;
        // resetPassword &&
        //   setCookie('resetPassword', JSON.stringify({ ...resetPassword, otp }));

        // const data = {
        //   result: t('Resister success')
        // }

        // const { result } = data

        // event_otp_verification(result);

        // setAuth('Change Password');
        // setLoading(false);
      } else {
        if (isFromLogin == 'Forgot Password by Email') {
          setCookie('otp', otp);
          deleteCookie('username');
          deleteCookie('mail');
          deleteCookie('mobilenumber');
          setAuth('Change Password By Email');
        } else if (isFromLogin == 'Forgot Password by Mobile') {
          const mobilenumber = getCookie('mobilenumber')
            ? getCookie('mobilenumber')
            : null;

          const { error } = await forgetPasswordVerifyOtpByMobile(
            handshake,
            mobilenumber,
            otp,
            locale
          );
          setLoading(false);
          if (error) {
            const result = t(error?.error || 'Verification Failed');
            event_otp_verification(result);
            // toast.error(t(error?.error || 'Verification Failed'));
            setFieldError('otp', t(error?.error || 'Verification Failed'));
          } else {
            const result = `result: ${t('Regestered successfully')}`;
            toast.success(result);
            setCookie('mobilenumber', mobilenumber);
            deleteCookie('username');
            deleteCookie('mail');
            deleteCookie('mobilenumber');
            setAuth('Change Password');
            setIsFromLogin('Change Password By Mobile');

            return;
          }
        } else {
          const mobilenumber = getCookie('mobilenumber')
            ? getCookie('mobilenumber')
            : null;
          const { data, error } = await loginVerifyOtpByMobile(
            handshake,
            mobilenumber,
            otp,
            locale,
            token
          );
          setLoading(false);

          if (error) {
            // const result = t(error?.error || 'Verification Failed');

            // event_otp_verification(result);
            const dataInfo = {
              method: 'Mobile',
              result: `Login Failed: ${error?.error}`,
              user_id: '',
            };
            const { method, result, user_id } = dataInfo;

            event_login(method, result, user_id);

            // toast.error(t(error?.error || 'Verification Failed'));
            setFieldError('otp', t(error?.error || 'Verification Failed'));
          } else {
            // const result = `result: ${t("Regestered successfully")}`
            const mage = data?.token;
            const userData = data?.user;

            const login = await signIn('credentials', {
              user: JSON.stringify({
                ...userData,
                token: mage,
                register_type: data?.register_type,
              }),
              token: mage,
              callbackUrl: '/',
              redirect: false,
            });

            if (login.ok) {
              firstActionAfterLoginHandler();
              const dataInfo = {
                method: 'Mobile',
                result: 'Login Success',
                user_id: userData?.id,
                email: userData?.email,
                phone: userData?.custom_attributes
                  ?.find((item) => item?.attribute_code == 'mobilenumber')
                  ?.value?.slice(2),
                first_name: userData?.firstname,
                last_name: userData?.lastname,
              };
              const {
                method,
                result,
                user_id,
                email,
                phone,
                first_name,
                last_name,
              } = dataInfo;

              event_login(
                method,
                result,
                user_id,
                email,
                phone,
                first_name,
                last_name,
                handleTime(),
                null,
                handleTime()
              );
              event_global_variables(
                locale,
                userData?.id,
                userData?.email,
                userData?.custom_attributes
                  ?.find((item) => item?.attribute_code == 'mobilenumber')
                  ?.value?.slice(2),
                userData?.city && userData?.city
              );

              toast.success(
                locale == 'en'
                  ? 'User Logged In SuccesFully'
                  : ' تم تسجيل الدخول بنجاح'
              );
              resetForm();

              const decodeMage = decodeJwt(mage);
              setCookie('mage', mage, {
                expires: new Date(decodeMage?.exp * 1000),
              });
              setCookie('mobilenumber', null);
              deleteCookie('username');
              deleteCookie('mail');
              deleteCookie('mobilenumber');
              pushToCheckoutHandler();
              setAuth(null);
              setIsFromLogin(false);
            }

            return;
          }
        }
      }
      setLoading(false);
      return;
    } else {
      setLoading(true);
      const mobilenumber = getCookie('mobilenumber')
        ? getCookie('mobilenumber')
        : null;
      const { error } = await registerVerifyOtpByMobile(
        handshake,
        mobilenumber,
        otp,
        locale
      );

      if (!error || error == 'null' || error == null) {
        const result = `Verification Success`;
        toast.success(`result: ${t('Regestered successfully')}`);
        event_otp_verification(result);
        deleteCookie('username');
        deleteCookie('mail');
        deleteCookie('mobilenumber');
        setAuth('Register');
      }
      setLoading(false);
    }
  };
  return (
    <>
      <div className='my-2.5 text-sm text-center sm:text-lg'>
        <span className='font-bold'>{t('Enter otp')} </span>
        <span className='text-zinc-500'>
          {t('which sent to confirm your identity')}
        </span>
      </div>
      <Formik
        initialValues={{
          otp: '',
          token: '',
          recaptcha: process.env.NEXT_PUBLIC_NODE_ENV === 'local',
        }}
        onSubmit={handleOTP}
        validationSchema={otpValidation(t)}
      >
        {({ setFieldValue }) => (
        <Form className='*:mb-1.5'>
          <div className='*:mb-1'>
            <Field
              dir={locale === 'en' ? 'ltr' : 'rtl'}
              name='otp'
              className='p-2.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm'
              placeholder={t('Otp')}
            />
            <ErrorMessage
              name='otp'
              component='p'
              className='text-sm text-red-600 sm:text-lg'
            />
          </div>
          <TurnstileRecaptcha
            action='login-verify-otp'
            className='flex justify-center items-center p-2'
            onVerify={(value) => {
              setFieldValue('recaptcha', true);
              setFieldValue('token', value);
            }}
          />
          <div className='flex gap-3 items-center'>
            <TimerCounter />
            {/* <button className='p-1.5 px-4 py-2 py-2.5 text-sm font-bold text-white bg-green-500 rounded-sm hover:bg-green-600 disabled:cursor-not-allowed sm:text-lg'>
              Resend
            </button> */}
            <button
              type='submit'
              disabled={loading}
              className='flex gap-2 justify-center items-center p-1.5 py-2.5 w-full text-sm font-bold text-white bg-black rounded-sm disabled:cursor-not-allowed sm:text-lg'
            >
              {loading && (
                <span className='rounded-full border-t border-r border-white animate-spin size-4' />
              )}

              <span>{t('Send')}</span>
            </button>
          </div>
        </Form>
        )}
      </Formik>
    </>
  );
};

export default Otp;
