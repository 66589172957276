import { priceFormatedObject, validatePrice } from '@/helpers/product';
import userStore from '@/hooks/useStore';
import { getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import CurrencyIcon from '../icons/CurrencyIcon';

const FreeShipping = ({ items, fromModal }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');
  const shipping_free_limit = getCookie('shipping_free_limit') || 499;
  const store = userStore(language);
  const total = items?.reduce((agg, curr) => {
    const price = validatePrice(priceFormatedObject(curr));

    return (
      agg +
      (curr?.extension_attributes?.is_eligible_for_free_shipping
        ? (price?.specialPrice || price?.originalPrice) * curr.qty
        : 0)
    );
  }, 0);

  const remainingAmount = shipping_free_limit - total || 0;

  const percentage = ((total / shipping_free_limit || 0) * 100).toFixed(2);

  return (
    <div className='*:mb-2 max-sm:text-[10px]'>
      <div className='flex flex-wrap items-center justify-between gap-1'>
        <p className='flex items-center gap-1 font-bold'>
          {t('Free Shipping For Any Orders Above')}{' '}
          <span className='flex items-center gap-1 text-[#2FB94D]'>
            {shipping_free_limit}{' '}
            {store !== 'ksa' ? t('SR') : <CurrencyIcon className='size-3' />}
          </span>
        </p>
        <p className='flex items-center gap-2 max-md:hidden'>
          <span>{t('Total')} :</span>
          <span className='flex items-center gap-1 font-bold'>
            {total}{' '}
            {store !== 'ksa' ? t('SR') : <CurrencyIcon className='size-3' />}
          </span>
        </p>
      </div>
      {remainingAmount > 0 && (
        <p className='flex items-center gap-1 sm:text-[15px]'>
          {t('Spend')}{' '}
          <span className='flex items-center gap-1 text-[#2FB94D]'>
            {remainingAmount?.toFixed(2)}{' '}
            {store !== 'ksa' ? t('SR') : <CurrencyIcon className='size-3' />}
          </span>{' '}
          {t('For Free Shipping')}
        </p>
      )}
      <div className='relative w-full h-5 text-center rounded-full bg-zinc-200 sm:h-6'>
        <div
          style={{
            width: percentage >= 100 ? '100%' : `${percentage}%`,
          }}
          className={`absolute start-0 top-0 h-full transition-all duration-500 max-sm:font-bold ${percentage >= 100 ? 'bg-[#2FB94D]' : `bg-black`} rounded-full text-center`}
        ></div>
        <div className='relative text-white'>
          {percentage > 100 ? 100 : percentage}%
        </div>
      </div>
      {!fromModal && (
        <p className='flex items-center gap-2 mt-1 md:hidden'>
          <span>{t('Total')} :</span>
          <span className='flex items-center gap-1 font-bold'>
            {total}{' '}
            {store !== 'ksa' ? t('SR') : <CurrencyIcon className='size-3' />}
          </span>
        </p>
      )}
    </div>
  );
};

export default FreeShipping;
