import { useRouter } from 'next/router';
// import Star from '../icons/Star';
import AddToCart from '../global/buttons/AddToCart';
import AddtoWishlist from '../global/buttons/AddtoWishlist';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import AddToCompare from '../product/AddToCompare';
import CloseIcon from '../icons/CloseIcon';
import Eye from '../icons/Eye';
import { useMemo, useState } from 'react';
import ProductQuickView from '../modals/QuikView/QuickView';
import { validatePrice } from '@/helpers/product';
import { event_select_item } from '@/utils/gtm';
import { handlePriceByComma } from '@/helpers/common/handlePrice';
import Image from '../global/image';
import { labelPositions } from '@/constants/Pdp';
import { getCookie } from 'cookies-next';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { countDownHandler } from '@/utils/global';
import SpecialOffer from '../product/SpecialOffer';
import { validateImgSrc } from '@/utils/global';
import NextImage from '../global/NextImage';
import { useUrlParams } from '@/context/url-params';
import userStore from '@/hooks/useStore';
import CurrencyIcon from '../icons/CurrencyIcon';

const ProductCard = ({
  fromFav,
  product,
  onRemoveCompare,
  fromBannerProductsList = false,
  categoryID = null,
  setCart = null,
  categoryName = null,
  loading,
}) => {
  const { locale, push } = useRouter();
  const { setUrlParams } = useUrlParams();
  const { t } = useTranslation('common');
  const [showQuickReview, setShowQuickReview] = useState(false);
  const store = userStore(locale);
  const price = validatePrice(product?.prices_with_tax);
  const sortedImages = useMemo(() => {
    return product?._media_?.image?.toSorted(
      (a, b) => +a.position - b.position
    );
  }, [product]);
  return (
    <>
      <section className='min-md:h-[442px] min-md:w-[220px] max-w-[220px] rounded-md bg-white p-2 text-sm sm:text-base'>
        {onRemoveCompare && (
          <div
            onClick={onRemoveCompare}
            className='bg-red absolute -right-3 -top-6 z-10 cursor-pointer rounded-md border border-[#CDCDCD] bg-white p-2'
          >
            <CloseIcon stroke='black' strokeWidth={2} width={14} height={14} />
          </div>
        )}
        {/* {product?.flash_sale_end_date &&
          product?.flash_sale_start_date &&
          countDownHandler(
            product?.flash_sale_end_date,
            product?.flash_sale_start_date
          )?.isCountDown && <SpecialOffer t={t} product={product} />} */}
        <SpecialOffer t={t} product={product} />
        <div
          className='relative mb-6 group'
          onClick={() => {
            push(`/product/${product?.rewrite_url}`);
            setUrlParams({
              ...(categoryID && { catId: categoryID }),
              ...(categoryName && { catname: categoryName }),
            });
            event_select_item(
              {
                ...product,
                item_list_id: categoryID,
                item_list_name: categoryName,
              },
              getCookie('couponCode') || null
            );
          }}
        >
          {sortedImages?.at(0)?.image ? (
            <NextImage
              loading={loading}
              src={validateImgSrc(
                sortedImages?.at(0)?.image ||
                  `${process.env.NEXT_PUBLIC_IMG_BASEURL}/catalog/product/${product?.image}`
              )}
              height={220}
              width={220}
              className='aspect-square h-[220px] w-full object-cover max-sm:max-h-[130px]'
              alt=''
            />
          ) : null}
          {product?.label?.map(
            (label, i) =>
              label &&
              product?.label[0]?.status === '1' && (
                <a
                  key={`card-${label.image}-${i}`}
                  className={`absolute z-[20] h-[220px]`}
                  style={labelPositions(
                    label?.frontend_settings?.position,
                    label?.frontend_settings?.image_size
                  )}
                  {...(label?.frontend_settings?.redirect_url && {
                    href: label?.frontend_settings?.redirect_url,
                  })}
                >
                  <Image
                    src={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/amasty/amlabel/${product?.label?.[0]?.frontend_settings?.image}`}
                    className={`max-w-[${(label?.frontend_settings?.image_size / 100) * 220}px]! `}
                    alt='label'
                    style={{
                      width: `${(label?.frontend_settings?.image_size / 100) * 220}px`,
                    }}
                  />
                </a>
              )
          )}
          <div
            // onClick={() => {
            //   push(`/product/${product?.rewrite_url}`);
            //   setUrlParams({
            //     ...(categoryID && { catId: categoryID }),
            //     ...(categoryName && { catname: categoryName }),
            //   });
            // }}
            className='invisible absolute inset-0 hidden cursor-pointer rounded-sm bg-[#00000066] text-[#DC2042] opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100 lg:block'
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowQuickReview(true);
              }}
              className='absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rounded-sm bg-white p-2 text-[#DC2042]'
            >
              <Eye />
            </button>
          </div>
        </div>
        {!fromBannerProductsList && (
          <div className='flex items-center justify-between gap-3 mb-2 max-sm:mb-1 max-sm:mt-2 max-sm:min-h-8'>
            {product?.category && Array.isArray(product?.category) ? (
              <span className='text-sm font-medium capitalize line-clamp-1 text-zinc-500 max-sm:text-xs'>
                {product?.category?.at(1)?.name}
              </span>
            ) : null}
            <div className='flex items-center gap-4'>
              <AddToCompare
                product={product}
                t={t}
                iconClassName='size-4 max-sm:w-4'
              />
              <AddtoWishlist fromFav={fromFav} product={product} />
            </div>
          </div>
        )}

        {product?.highlight?.name ? (
          <Link
            prefetch={false}
            href={`/product/${product?.rewrite_url}`}
            onClick={() => {
              setUrlParams({
                ...(categoryID && { catId: categoryID }),
                ...(categoryName && { catname: categoryName }),
              });
              event_select_item(
                {
                  ...product,
                  item_list_id: categoryID,
                  item_list_name: categoryName,
                },
                getCookie('couponCode') || null
              );
            }}
            className='-mt-0.5 line-clamp-2 min-h-12 font-[500] max-sm:min-h-9 max-sm:text-xs'
          >
            <span
              dangerouslySetInnerHTML={{
                __html: product?.highlight?.name || '',
              }}
            />
          </Link>
        ) : (
          <Link
            prefetch={false}
            href={`/product/${product?.rewrite_url}`}
            onClick={() => {
              setUrlParams({
                ...(categoryID && { catId: categoryID }),
                ...(categoryName && { catname: categoryName }),
              });
              event_select_item(
                {
                  ...product,
                  item_list_id: categoryID,
                  item_list_name: categoryName,
                },
                getCookie('couponCode') || null
              );
            }}
            className='-mt-0.5 line-clamp-2 min-h-12 font-[500] max-sm:min-h-9 max-sm:text-xs'
          >
            <h2>{product?.name?.at(0) || ''}</h2>
          </Link>
        )}

        {/* {!fromBannerProductsList && (
          <div className='flex items-center gap-1 my-1'>
            <Star className='size-4 text-[#F1C40F]' />

            <span className='font-bold'>
              {(ratings &&
                (ratings[product?.entity_id]?.overAll_rating * 0.05 > 0
                  ? (
                      ratings[product?.entity_id]?.overAll_rating * 0.05
                    )?.toFixed(2)
                  : ratings[product?.entity_id]?.overAll_rating * 0.05)) ||
                0}
            </span>
            <span className=''>
              ({(ratings && ratings[product?.entity_id]?.total_reviews) || 0})
            </span>
          </div>
        )} */}
        <div className='flex items-center justify-between my-1'>
          <div className='flex flex-col'>
            <span
              className={`flex items-center gap-0.5 text-base font-bold max-sm:text-xs ${price?.specialPrice ? 'text-[#DC2042]' : 'text-black'}`}
            >
              {`${handlePriceByComma(price?.specialPrice || price?.originalPrice)}`}
              {store === 'ksa' ? '' : t('SR')}
              {store === 'ksa' && <CurrencyIcon className='size-3.5' />}
            </span>
            {/* <span className='-mt-1 line-through sm:text-[15px] md:text-base'>
            2500 SR
          </span> */}
            {
              <span
                className={`-mt-1 flex items-center gap-0.5 text-[10px] line-through max-sm:text-[7px] ${price?.specialPrice ? 'visible' : 'invisible'}`}
              >
                {handlePriceByComma(price?.originalPrice)}{' '}
                {store === 'ksa' ? '' : t('SR')}
                {store === 'ksa' && <CurrencyIcon className='size-2.5' />}
              </span>
            }
          </div>
          {/* <div
            className={`flex flex-col text-[10px] max-sm:text-[7px] min-[420px]:text-sm ${price?.specialPrice ? 'visible' : 'invisible'}`}
          >
            {product?.show_discount_price && (
              <span className='text-red-500'>
                {' '}
                {`${Number(price?.discount).toFixed(0)}%`} {t('off')}
              </span>
            )}
            <span
              className={`text-[10px] max-sm:text-[7px] min-[420px]:text-sm ${price?.specialPrice ? 'visible' : 'invisible'}`}
            >
              {`${locale === 'en' ? 'Save' : 'وفر'} ${(price?.originalPrice - price?.specialPrice).toFixed(2)?.replace('.00', '')} ${t('SR')}`}
            </span>
          </div> */}
          {price?.specialPrice && (
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              direction='vertical'
              slidesPerView={1}
              loop={!!product?.show_discount_price}
              className='h-8 mx-0 top-header-swiper'
              spaceBetween={10}
            >
              <SwiperSlide className='text-sm font-bold text-[#2FB94D] max-sm:text-[10px]'>
                <div className='flex items-center gap-0.5'>
                  <p className='text-nowrap'>{`${locale === 'en' ? 'Save' : 'وفر'} ${(price?.originalPrice - price?.specialPrice).toFixed(2)?.replace('.00', '')} ${store !== 'ksa' ? t('SR') : ''}`}</p>
                  {store === 'ksa' && <CurrencyIcon className='size-3' />}
                </div>
              </SwiperSlide>
              {product?.show_discount_price && (
                <SwiperSlide className='text-sm font-bold text-[#2FB94D] max-sm:text-[10px]'>
                  <p className='text-nowrap'>
                    {`${Math.floor(Number(price?.discount))}%`} {t('off')}
                  </p>
                </SwiperSlide>
              )}
            </Swiper>
          )}
        </div>
        <AddToCart {...{ categoryName, categoryID, product, setCart }} />
      </section>

      {showQuickReview && (
        <ProductQuickView
          onHide={() => setShowQuickReview(false)}
          show={showQuickReview}
          setShow={setShowQuickReview}
          product={product}
          img={`${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/catalog/product${product?.image?.[0]}`}
        />
      )}
    </>
  );
};

export default ProductCard;
