import TurnstileRecaptcha from '@/components/turnstile';
import userStore from '@/hooks/useStore';
import { otpValidation } from '@/utils/valitation/auth';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useRouter } from 'next/router';
import React from 'react';

const MobileOtpRegister = ({ t, handleOtp, loading }) => {
  const { locale } = useRouter();
  const store = userStore(locale);

  return (
    <>
      <div className='my-2.5 text-sm text-center sm:text-lg'>
        <span className='font-bold'>{t('Enter otp')} </span>
        <span className='text-zinc-500'>
          {t(
            store === 'ksa'
              ? 'which sent to confirm your identity in phone'
              : 'which sent to confirm your identity'
          )}
        </span>
      </div>
      <Formik
        initialValues={{
          otp: '',
          token: '',
          recaptcha: process.env.NEXT_PUBLIC_NODE_ENV === 'local',
        }}
        onSubmit={handleOtp}
        validationSchema={otpValidation(t)}
      >
        {({ setFieldValue }) => (
          <Form className='*:mb-1.5'>
            <div className='*:mb-1'>
              <Field
                dir={locale === 'en' ? 'ltr' : 'rtl'}
                name='otp'
                className='p-2.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm'
                placeholder={t('Otp')}
              />
              <ErrorMessage
                name='otp'
                component='p'
                className='text-sm text-red-600 sm:text-lg'
              />
            </div>
            <TurnstileRecaptcha
              action='register-verify-otp'
              className='flex justify-center items-center p-2'
              onVerify={(value) => {
                setFieldValue('recaptcha', true);
                setFieldValue('token', value);
              }}
            />
            <button
              type='submit'
              disabled={loading}
              className='flex gap-2 justify-center items-center p-1.5 py-2.5 w-full text-sm font-bold text-white bg-black rounded-sm disabled:cursor-not-allowed disabled:opacity-75 sm:text-lg'
            >
              {loading && (
                <span className='rounded-full border-t border-r border-white animate-spin size-4' />
              )}

              <span>{t('Send')}</span>
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MobileOtpRegister;
