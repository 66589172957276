import Image from "next/image";

const imgLoader = ({ src, width, quality }) => {
  return `${src}?width=${width}&quality=${quality || 80}&format=webp`;
};

const NextImage = ({
  className,
  src,
  alt,
  width,
  height,
  loading = 'lazy',
  ...props
}) => {
  return (
    <Image
      src={src?.replace('//media', '/media')}
      alt={alt}
      className={className}
      width={width}
      height={height}
      loading={loading}
      loader={imgLoader}
      {...props}
    />
  );
};

export default NextImage;
