import Link from 'next/link';
import toast from 'react-hot-toast';
import ShoppingCart from '@/components/icons/ShoppingCart';

import {
  event_add_to_cart,
  event_checkout_started,
  event_remove_from_cart,
} from '@/utils/gtm';
import { deleteCartItem, updateCartItem } from '@/services/cart';
import { priceFormatedObject, validatePrice } from '@/helpers/product';
import { calcCartTotalHandler } from '@/helpers/cart';
import { getCookie, getCookies, setCookie } from 'cookies-next';
import { useCart } from '@/context/cart';
import { useState } from 'react';
import { handlePriceByComma } from '@/helpers/common/handlePrice';
import RemoveIcon from '@/components/icons/Remove';
import { handleProductForGTMEvents, validateImgSrc } from '@/utils/global';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import NextImage from '@/components/global/NextImage';
import userStore from '@/hooks/useStore';
import CurrencyIcon from '@/components/icons/CurrencyIcon';

const CartHeader = ({ t, locale }) => {
  const cart = useCart();
  const [qty, setQty] = useState(null);
  const [dropDown, setDropDown] = useState(false);
  const { handshake, mage, quote } = getCookies();
  const [delLoading, setDelLoading] = useState(false);
  const [updLoading, setUpdLoading] = useState(false);
  const { push } = useRouter();
  const store = userStore(locale);
  const cartTotal = calcCartTotalHandler(cart?.cart?.cartItems || []);
  const { data: session } = useSession();
  const handleDeleteCartItem = async (itemData) => {
    setDelLoading(true);
    const res = await deleteCartItem(handshake, mage, quote, itemData?.item_id);

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      cart?.dispatch({ type: 'clear' });
    }

    if (res?.status === 200) {
      event_remove_from_cart(
        handleProductForGTMEvents(itemData, itemData?.qty),
        itemData?.qty,
        getCookie('couponCode') || itemData?.coupon_code
      );

      toast.success(
        locale?.includes('en')
          ? 'Item Deleted From Cart!'
          : 'تم مسح المنتج من العربة'
      );
      cart?.dispatch({
        type: 'remove',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id },
      });
      cart?.dispatch({
        type: 'is_state_update',
        payload: true,
      });

      setTimeout(() => {
        cart?.dispatch({
          type: 'is_state_update',
          payload: false,
        });
      }, 1500);
      setDelLoading(false);
    } else {
      toast.error(
        locale?.includes('en')
          ? 'Error Occured, please try again'
          : 'حذث خطأ, برجاء المحاولة مره اخرى'
      );
      setDelLoading(false);
    }
  };

  const handleUpdateItem = async (itemData) => {
    const selectedItemData = cart?.cart?.cartItems?.find(
      (item) => item?.item_id == itemData?.item_id
    );
    console.log('selectedItemData', selectedItemData?.qty);

    setUpdLoading(true);
    const res = await updateCartItem(
      handshake,
      mage,
      quote,
      itemData?.item_id,
      qty
    );

    if (res?.data?.quoteId !== undefined && res?.data?.quoteId != quote) {
      setCookie('quote', res?.data?.quoteId);
      cart?.dispatch({ type: 'clear' });
    }

    if (res?.status === 200) {
      // if (qty > selectedItemData?.qty) {
      //   event_add_to_cart(itemData, qty);
      // } else {
      //   event_remove_from_cart(handleProductForGTMEvents(itemData, qty), qty, getCookie("couponCode"));
      // }

      let quantity = qty;
      if (+itemData?.qty < qty) {
        quantity = qty - +itemData?.qty;
        event_add_to_cart(
          handleProductForGTMEvents(itemData, quantity),
          quantity,
          getCookie('coupon_code') || itemData?.coupon_code
        );
      } else {
        quantity = +itemData?.qty - qty;
        console.log(
          'quantity, +itemData?.qty, qty',
          quantity,
          +itemData?.qty,
          qty
        );
        console.log('itemData', itemData);
        event_remove_from_cart(
          handleProductForGTMEvents(itemData, quantity),
          quantity,
          getCookie('coupon_code') || itemData?.coupon_code
        );
      }
      toast.success(
        locale?.includes('en')
          ? 'Item Updated Successfully'
          : 'تم تعديل كمية المنتج بنجاح'
      );
      cart?.dispatch({
        type: 'update',
        payload: { item_id: itemData?.item_id || itemData?.data?.item_id, qty },
      });

      cart?.dispatch({
        type: 'is_state_update',
        payload: true,
      });

      setTimeout(() => {
        cart?.dispatch({
          type: 'is_state_update',
          payload: false,
        });
      }, 1500);
      setUpdLoading(false);
    } else {
      toast.error(
        res?.error?.error?.message
          ? t(res?.error?.error?.message)
          : locale?.includes('en')
            ? 'Error Occured, please try again'
            : 'حذث خطأ, برجاء المحاولة مره اخرى'
      );

      if (
        res?.error?.error?.message ===
        'There are no source items with the in stock status'
      ) {
        await handleDeleteCartItem(itemData);
      }
      setUpdLoading(false);
    }
  };

  // console.log('cart?.cart?.cart//////////', cart?.cart?.cart)
  return (
    <div className='relative max-md:hidden'>
      <section
        onClick={() => cart?.cart?.cartItems?.length > 0 && setDropDown(true)}
        className='flex items-center gap-3 cursor-pointer'
      >
        <div className='relative'>
          {cart?.items?.length > 0 && (
            <span className='absolute flex items-center justify-center text-xs text-white bg-black rounded-full -right-2 -top-2 size-5'>
              {cart?.items?.length}
            </span>
          )}
          <ShoppingCart />
        </div>
        <div className='flex flex-col'>
          <span className='text-zinc-500'>{t('Total')}</span>
          <span className='flex items-center gap-1 font-bold'>
            {cartTotal ? cartTotal.toFixed(2) : 0}{' '}
            {store !== 'ksa' ? t('SR') : <CurrencyIcon className='size-4' />}
          </span>
        </div>
      </section>

      {dropDown && cart?.cart?.cartItems?.length > 0 ? (
        <>
          <div
            onClick={() => setDropDown(false)}
            className='fixed start-0 top-0 z-[600] h-screen w-screen bg-black bg-opacity-60'
          ></div>
          <div className='after:shadow-3xl after:absolute after:start-4 after:top-[60%] after:z-[800] after:size-4 after:border-[10px] after:border-b-white after:border-e-transparent after:border-s-transparent after:border-t-transparent'></div>
          <div className='gray-scroll absolute end-0 top-full z-[800] max-h-[400px] w-96 overflow-y-auto rounded-md border bg-white p-3 pb-0 shadow-xl'>
            {cart?.cart?.cartItems?.map((item, i) => {
              const price = validatePrice(priceFormatedObject(item));

              return (
                <div
                  key={`cart-header-${i}`}
                  className='flex gap-2 py-2 border-b first:pt-0 last:border-b-0'
                >
                  <Link
                    href={`/product/${item?.rewrite_url || item?.extension_attributes?.url_key}`}
                  >
                    <NextImage
                      src={validateImgSrc(
                        item?._media_?.image?.[0]?.image ||
                          `${process.env.NEXT_PUBLIC_IMG_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`
                      )}
                      height={155}
                      width={131}
                      className='object-cover w-32 h-full border rounded-md'
                      alt='Cart Image'
                    />
                  </Link>
                  <div className='flex flex-col flex-1 gap-2'>
                    <div className='flex justify-between'>
                      <Link
                        href={`/product/${item?.rewrite_url || item?.extension_attributes?.url_key}`}
                        className='flex-1 text-sm line-clamp-2'
                      >
                        {item?.name || ''}
                      </Link>
                      <button
                        onClick={() => handleDeleteCartItem(item)}
                        className='disabled:cursor-not-allowed'
                        disabled={delLoading}
                      >
                        <RemoveIcon />
                      </button>
                    </div>
                    <p className='flex items-center gap-1 text-lg font-bold'>
                      {handlePriceByComma(
                        price?.specialPrice || price?.originalPrice
                      )}{' '}
                      {store !== 'ksa' ? (
                        t('SR')
                      ) : (
                        <CurrencyIcon className='size-4' />
                      )}
                    </p>
                    <div className='flex items-center justify-between'>
                      <span className='text-sm'>
                        {locale?.includes('en') ? 'Qty' : 'الكمية'}
                      </span>
                      <div className='flex items-center gap-2'>
                        <select
                          onChange={(e) => setQty(Number(e.target.value))}
                          className='w-12 border rounded-md outline-none cursor-pointer'
                        >
                          {[...Array(10).keys()].map((i) => (
                            <option
                              key={`cart-header-2-${i}`}
                              selected={i === Number(item?.qty || 2) - 1}
                              value={i + 1}
                            >
                              {i + 1}
                            </option>
                          ))}
                        </select>
                        <button
                          disabled={updLoading}
                          onClick={() => handleUpdateItem(item)}
                          className='w-24 rounded-md bg-black p-1.5 text-sm text-white disabled:cursor-not-allowed'
                        >
                          {locale?.includes('en') ? 'Update' : 'تعديل'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className='sticky left-0 z-50 flex flex-col gap-2 py-2 bg-white -bottom-3'>
              <div className='flex items-center justify-center gap-3'>
                <span className='font-semibold'>
                  {locale?.includes('en') ? 'Sub Total' : 'الإجمالى'} :
                </span>
                <span className='flex items-center gap-1 text-lg font-bold text-black'>
                  {cartTotal ? cartTotal.toFixed(2) : 0}{' '}
                  {store !== 'ksa' ? (
                    t('SR')
                  ) : (
                    <CurrencyIcon className='size-4' />
                  )}
                </span>
              </div>
              <Link
                href='/cart'
                onClick={() => setDropDown(false)}
                className='block w-full p-2 text-sm font-semibold text-center border border-black rounded-md'
              >
                {locale?.includes('en')
                  ? 'View My Cart'
                  : 'الذهاب لعربة التسوق'}
              </Link>
              {!!session?.user && (
                <span
                  onClick={() => {
                    event_checkout_started(
                      cart?.cart?.cart?.items,
                      cart?.cart?.cart?.total_segments?.find(
                        (seg) => seg?.code == 'grand_total'
                      )?.value,
                      getCookie('couponCode') || cart?.cart?.cart?.coupon_code
                    );
                    push('/checkout');
                    setDropDown(false);
                  }}
                  className='block w-full p-2 text-sm font-semibold text-center text-white bg-black border border-black rounded-md cursor-pointer'
                >
                  {locale?.includes('en') ? 'Checkout' : 'الدفع'}
                </span>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CartHeader;
