import { getProductsByCategoryId } from '@/services/category';
import { productsSearch } from '@/services/search';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import useClearPLPProductsCache from './useClearCachePLPProducts';

const useFilterProducts = ({
  setAllFilterKeys,
  setAllProducts,
  allSortingKeys,
  categoryID,
  setMoreProductsIsLoading,
  filters,
  products,
  fromCategory = true,
  word = '',
  setPageIndex,
  setAllSortingKeys,
  brand,
}) => {
  const [selectedFilterKeys, setSelectedFilterKeys] = useState([]);
  const [allFilters, setAllFilters] = useState(filters);
  const { locale } = useRouter();
  const handshake = getCookie('handshake');
  const {clearPLPProductsCache} = useClearPLPProductsCache(categoryID)

  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}`
      : word
        ? `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${selectedFilterKeys?.join('&')}`
        : null,
    async (_, { arg }) => {
      return fromCategory
        ? await getProductsByCategoryId(
            handshake,
            categoryID,
            arg?.sortKeys,
            arg?.pageSize,
            arg?.pageNo,
            false,
            arg?.filterKeys,
            brand
          )
        : await productsSearch(
            handshake,
            arg?.word,
            arg?.sortKeys,
            arg?.pageNo,
            arg?.filterKeys,
            false
          );
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  useEffect(() => {
    setAllFilters(filters);
  }, [filters]);

  useEffect(() => {
    setSelectedFilterKeys([]);
    setAllFilterKeys('');
    return () => {
      setSelectedFilterKeys([]);
      setAllFilterKeys('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useCallback(
    async ({ code, item }) => {
      console.log('code, item useFilterProducts', code, item);
      let filterKeys = '';
      if (selectedFilterKeys?.includes(`${code}=${item?.Key}`)) {
        const updatedSelectedFilterKeys = selectedFilterKeys?.filter(
          (itemF) => itemF != `${code}=${item?.Key}`
        );
        setSelectedFilterKeys(updatedSelectedFilterKeys);
        filterKeys = `${updatedSelectedFilterKeys?.join('&')}`;
      } else {
        setSelectedFilterKeys((prev) => [...prev, `${code}=${item?.Key}`]);
        filterKeys = `${selectedFilterKeys?.join('&')}&${code}=${item?.Key}`;
      }

      setAllFilterKeys(filterKeys);
      // console.log('filterKeys', filterKeys);
      setPageIndex(1);
      
      clearPLPProductsCache()

      let resResult = await res?.trigger(
        fromCategory
          ? {
              pageNo: 0,
              pageSize: 30,
              categoryID,
              locale,
              filterKeys,
              sortKeys: allSortingKeys,
            }
          : {
              pageNo: 0,
              pageSize: 30,
              sortKeys: allSortingKeys,
              word,
              filterKeys,
            }
      );

      const result = resResult?.data?.data || {};
      setAllProducts(
        result?.products?.length && result?.products != undefined
          ? result?.products
          : []
      );
      setMoreProductsIsLoading && setMoreProductsIsLoading(false); // to getting more products after scrolling
      // result?.aggregate?.length && result?.aggregate != undefined && setAllFilters(result?.aggregate);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allSortingKeys,
      categoryID,
      fromCategory,
      locale,
      res,
      selectedFilterKeys,
      word,
    ]
  );

  const handleResetFilter = async () => {
    setSelectedFilterKeys([]);
    setAllSortingKeys('sortBy=price&sortDir=DESC');
    setAllProducts(products);

    let result = await res?.trigger(
      fromCategory
        ? {
            pageNo: 0,
            pageSize: 30,
            categoryID,
            filterKeys: '',
          }
        : {
            pageNo: 0,
            pageSize: 30,
            sortKeys: allSortingKeys,
            word,
            filterKeys: '',
          }
    );
    result?.products?.length &&
      result?.products != undefined &&
      setAllProducts(result?.products);
  };

  return {
    selectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    setAllFilters,
  };
};

export default useFilterProducts;
