import { useRef, useState } from 'react';

import Otp from '../forms/auth/Otp';
import Login from '../forms/auth/Login';
import Modal from '@/components/modals';
import SuccessChangePass from './SuccessChangePass';
import CloseIcon from '@/components/icons/CloseIcon';
// import useHandleOutSide from '@/hooks/useHandleOutSide';
import ForgotPassword from '../forms/auth/ForgotPassword';
import ChangePassword from '../forms/auth/ChangePassword';
import Register from '../forms/auth/register/Register';
// import { deleteCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import { deleteCookie } from 'cookies-next';

const AuthModal = ({ auth, setAuth }) => {
  const ref = useRef();
  const { t } = useTranslation('common');
  // useHandleOutSide(ref, () => {
  //   deleteCookie('mobilenumber');
  //   deleteCookie('mail');
  //   deleteCookie('username');
  //   setAuth(false);
  // });
  const [isFromLogin, setIsFromLogin] = useState(false);

  return (
    <Modal show={auth} isCenter>
      <div
        ref={ref}
        className='gray-scroll mx-auto max-h-[95vh] w-[550px] overflow-y-auto rounded-md bg-white p-2.5 pb-1 max-sm:w-[97%]'
      >
        <div className='flex justify-between items-center pb-2 border-b'>
          <p className='text-lg font-bold'>{t(auth)}</p>
          <CloseIcon
            onClick={() => {
              setAuth(false);
              deleteCookie('mobilenumber');
              deleteCookie('mail');
              deleteCookie('username');
            }}
            className='size-5 cursor-pointer text-[#005471]'
          />
        </div>
        {(auth === 'Login' || auth === 'Register') && (
          <div className='grid grid-cols-2 my-4'>
            <button
              onClick={() => setAuth('Login')}
              className={`p-2 text-sm font-bold sm:text-lg ${
                auth === 'Login' ? 'bg-black text-white' : 'text-[#005471]'
              } rounded-s-md border`}
            >
              {t('Login')}
            </button>
            <button
              onClick={() => setAuth('Register')}
              className={`p-2 text-sm font-bold sm:text-lg ${
                auth === 'Register' ? 'bg-black text-white' : 'text-[#005471]'
              } rounded-e-md border`}
            >
              {t('Register')}
            </button>
          </div>
        )}
        {auth === 'Login' ? (
          <Login t={t} setAuth={setAuth} setIsFromLogin={setIsFromLogin} />
        ) : auth === 'Register' ? (
          <Register t={t} setAuth={setAuth} setIsFromLogin={setIsFromLogin} />
        ) : auth === 'Forgot Password' ? (
          <ForgotPassword {...{ t, setAuth, isFromLogin, setIsFromLogin }} />
        ) : auth === 'Otp' ? (
          <Otp {...{ t, setAuth, isFromLogin, setIsFromLogin }} />
        ) : auth === 'Change Password' ||
          auth === 'Change Password By Email' ? (
          <ChangePassword {...{ t, setAuth, isFromLogin, setIsFromLogin }} />
        ) : auth === 'Success Change Password' ? (
          <SuccessChangePass t={t} setAuth={setAuth} />
        ) : null}
      </div>
    </Modal>
  );
};

export default AuthModal;
