import ColoredWhatsappIcon from '@/components/icons/ColoredWhatsappIcon';
import Link from 'next/link';

const FloatingWhatsapp = () => {
  return (
    <Link
      href='https://wa.me/966920005296'
      target='_blank'
      className={`fixed w-fit bottom-16 start-4 z-[350] max-sm:bottom-28 max-sm:end-4`}
    >
      <ColoredWhatsappIcon width={50} height={50} />
    </Link>
  );
};

export default FloatingWhatsapp;
