import Link from 'next/link';
import { useRouter } from 'next/router';
import NextImage from '../global/NextImage';
import { validateImgSrc } from '@/utils/global';

const CategoryCard = ({ img, name, url = '/', loading = 'lazy' }) => {
  const { query } = useRouter();
  return (
    <Link
      prefetch={false}
      href={'/' + Object.values(query)?.join('/').concat(`/${url}`)}
      className={`flex h-full min-h-32 cursor-pointer flex-col justify-between gap-2 rounded-md border p-3 shadow-sm hover:bg-rose-50`}
    >
      <NextImage
        loading={loading}
        src={validateImgSrc(img)}
        height={14}
        width={60}
        className='mx-auto h-14 w-[50px] object-contain'
        alt={name}
      />
      <span
        title={name}
        className={`line-clamp-2 min-h-9 text-center text-xs font-bold sm:min-h-12`}
      >
        {name}
      </span>
    </Link>
  );
};

export default CategoryCard;
