import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import useSortProducts from '../category/useSortProducts';
import useInfiniteProducts from '../category/useInfiniteProducts';
import useFilterProducts from '../category/useFilterProducts';
import useSWRMutation from 'swr/mutation';
import { event_product_searched } from '@/utils/gtm';
import useClickOutside from '../global/useClickOutside';
import { productsSearch } from '@/services/search';
import { getCookie } from 'cookies-next';

const useSearchBox = (ourSearchContainer, ourSearchInput, ourSearchButton, inputValue, setInputValue, setLoadingSearch) => {
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const { events } = useRouter();
  const ref = useRef();
  const [filter, setFilter] = useState(false);
  const handshake = getCookie("handshake")

  const res = useSWRMutation(
    () => `/api/search?word=${inputValue}`,
    async (_, { arg }) => {
      return await productsSearch(
        handshake,
        arg?.word,
        arg?.sortKeys,
        0,
        arg?.filterKeys,
        false
      )
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const {
    allProducts,
    setAllProducts,
    allFilterKeys,
    setAllFilterKeys,
    allSortingKeys,
    setAllSortingKeys,
    setPageIndex,
    setMoreProductsIsLoading,
  } = useInfiniteProducts({ products, word: inputValue, fromCategory: false });

  const {
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    setAllFilters,
  } = useFilterProducts({
    setAllFilterKeys,
    allFilterKeys,
    setAllProducts,
    setAllSortingKeys,
    products,
    setMoreProductsIsLoading,
    fromCategory: false,
    allSortingKeys,
    filters: 'filterList',
    setPageIndex,
    word: inputValue,
  });
  
  const { handleSorting } = useSortProducts({
    setAllSortingKeys,
    setAllProducts,
    fromCategory: false,
    allSortingKeys,
    allFilterKeys,
    setMoreProductsIsLoading,
    setPageIndex,
    word: inputValue,
  });

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      setAllFilters([]);
      setAllProducts([]);
      setTotalProducts(0);
      setLoadingSearch(true);
      setInputValue('');
    });
  }, [events, setAllFilters, setAllProducts, setInputValue, setLoadingSearch]);

  useClickOutside([ourSearchContainer, ourSearchInput, ourSearchButton, ref], () => {
    setAllFilters([]);
    setAllProducts([]);
    setTotalProducts(0);
    setLoadingSearch(true);
    setInputValue('');
    setFilter(false);
  });

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const fetchProductsBySearchWord = async () => {
        setLoadingSearch(true);

        if (inputValue && inputValue != undefined && inputValue.length > 2) {
          let resResult = await res?.trigger({
            pageNo: 0,
            filterKeys: allFilterKeys,
            sortKeys: allSortingKeys,
            word: inputValue,
          });
          const result = resResult?.data?.data || {}
          event_product_searched(inputValue, res?.data?.totalProducts);
          
          setSuggestedProducts(
            result?.suggestion &&
              result?.suggestion?.['product_suggestions'] !== undefined
              ? result?.suggestion?.['product_suggestions']
              : []
          );
          setAllFilters(
            result?.aggregate?.length && result?.aggregate != undefined
              ? result?.aggregate
              : []
          );
          setAllProducts(
            result?.products?.length && result?.products != undefined
              ? result?.products
              : []
          );
          setProducts(
            result?.products?.length && result?.products != undefined
              ? result?.products
              : []
          );
          setTotalProducts(
            result?.totalProducts != undefined ? result?.totalProducts : 0
          );
          setLoadingSearch(false);
        } else {
          setAllFilters([]);
          setAllProducts([]);
          setProducts([]);
          setTotalProducts(0);
          setLoadingSearch(true);
        }
      };
      fetchProductsBySearchWord();
    }, 500);

    return () => clearTimeout(debounceTimeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Esc key (key code 27)
      if (event.key === 'Escape' || event.keyCode === 27) {
        setAllFilters([]);
        setAllProducts([]);
        setTotalProducts(0);
        setLoadingSearch(false);
        setInputValue('');
      }
    };

    // Add event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    allProducts,
    handleSorting,
    totalProducts,
    selectedFilterKeys,
    setSelectedFilterKeys,
    handleFilter,
    handleResetFilter,
    allFilters,
    ref,
    filter,
    setFilter,
    suggestedProducts,
  };
};

export default useSearchBox;
