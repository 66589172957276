import { getAmastyProducts, getProductsBySkus } from '@/services/product';
import { useState } from 'react';
import useSWR from 'swr';

// Cache to store API responses and prevent duplicate calls
const amastyCache = new Map();

const fetchProducts = async (handshake, parentProduct, type, state) => {
  if (state?.cartRelatedProducts) {
    return state.cartRelatedProducts;
  }

  const sku = parentProduct?.sku;
  if (!sku) return [];

  // If the request for this SKU is already in progress, wait for it
  if (!amastyCache.has(sku)) {
    amastyCache.set(
      sku,
      getAmastyProducts(handshake, sku).then((res) => res?.data?.data?.at(0))
    );
  }

  // Wait for the cached promise to resolve
  const amastyRes = await amastyCache.get(sku);
  const amastySKUs = amastyRes?.[type];

  if (!amastySKUs?.length) return [];

  const res = await getProductsBySkus(handshake, amastySKUs, false);
  return res?.data?.data?.products?.filter((product) => product?.stock.is_in_stock) || [];
};

export const useRelatedAndUpsellProducts = (product, handshake, language, state) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [upsellProducts, setUpsellProducts] = useState([]);

  useSWR(
    product?.sku ? `related-products-${product.sku}-${language}` : null,
    () => fetchProducts(handshake, product, 'related', state),
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 30 * 60,
      onSuccess: setRelatedProducts,
      onError: (error) => console.error('Error fetching related products:', error),
    }
  );

  useSWR(
    product?.sku ? `upsell-products-${product.sku}-${language}` : null,
    () => fetchProducts(handshake, product, 'upsell', state),
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 30 * 60,
      onSuccess: setUpsellProducts,
      onError: (error) => console.error('Error fetching upsell products:', error),
    }
  );

  return { relatedProducts, upsellProducts };
};
