import useHandleOutSide from '@/hooks/useHandleOutSide';
import Brands from './filters/Brands';
import { useRef } from 'react';
import Link from 'next/link';
// import Prices from './filters/Prices';
// import Colors from './filters/Colors';
// import Rating from './filters/Rating';

const SideMenu = ({
  isModal = false,
  filterList,
  selectedFilterKeys,
  handleFilter,
  handleResetFilter,
  setShowFilter,
  // fromSearch = null,
  setInputValue,
  suggestedWords,
  suggestedProducts,
  t,
}) => {
  const ref = useRef();

  useHandleOutSide(ref, () => isModal && setShowFilter(false));

  return (
    <div
      ref={ref}
      className={!isModal ? 'col-span-3 hidden *:mb-3 md:block' : 'p-3'}
    >
      {suggestedWords?.length > 0 && (
        <>
          <h2 className='font-semibold sm:text-lg'>{t('recommondedWords')}</h2>
          <div className='flex flex-wrap gap-2 rounded-md border p-2'>
            {suggestedWords?.map((word, key) => (
              <button
                key={`suggest-word-${key}`}
                onClick={() => setInputValue(word)}
                className='rounded-md border px-2 py-1 text-sm hover:bg-slate-200'
              >
                {word}
              </button>
            ))}
          </div>
        </>
      )}
      {suggestedProducts?.length &&
        suggestedProducts?.at(0)?.options?.length > 1 && (
          <>
            <div className='font-semibold sm:text-lg'>
              {t('recommondedProducts')}
            </div>
            <div className='flex flex-col gap-2 rounded-md border p-2'>
              {(suggestedProducts?.at(0)?.options || [])?.map(
                ({ _source: item }) => (
                  <Link
                    className='border-b pb-2 last:border-none hover:text-slate-700'
                    key={`source-${item.entity_id}`}
                    href={`/product/${item?.rewrite_url}`}
                  >
                    {item?.name || ''}
                  </Link>
                )
              )}
            </div>
          </>
        )}
      {!isModal &&
      (filterList || [])
        ?.map((item) => item?.filterable_values)
        ?.filter((item) => item != null)?.length ? (
        <button
          disabled={selectedFilterKeys?.length == 0}
          onClick={handleResetFilter}
          className={`mb-3 rounded bg-black px-4 py-2 text-white ${
            selectedFilterKeys?.length == 0
              ? 'cursor-not-allowed opacity-50'
              : null
          }`}
        >
          {t('Reset Filter')}
        </button>
      ) : null}
      {filterList?.map((filterSection, key) => {
        const title = filterSection?.label || '';
        const options = filterSection?.filterable_values || [];
        const code = filterSection?.filter_key || '';
        return options?.length ? (
          <div
            key={`filter-section-${key}`}
            className={!isModal ? 'rounded-md border p-3' : 'border-b pb-3'}
          >
            <div className='mb-2 font-bold '>{title}</div>
            <Brands
              {...{ options, selectedFilterKeys, code, handleFilter, t }}
            />
          </div>
        ) : null;
      })}
      {/* <div className={!isModal ? 'p-3 border rounded-md' : "py-3 border-b"}>
        <h2 className='font-bold'>{t('Prices')}</h2>
        <Prices t={t}/>
      </div>
      <div className={!isModal ? 'p-3 border rounded-md' : "py-3 border-b"}>
        <h2 className='font-bold'>{t('Colors')}</h2>
        <Colors />
      </div>
      <div className={!isModal ? 'p-3 border rounded-md' : "py-3"}>
        <h2 className='font-bold'>{t('Ratings')}</h2>
        <Rating />
      </div> */}
    </div>
  );
};

export default SideMenu;
