import UseLocale from '@/hooks/useLocale';
import { decrypt, encrypt } from '@/utils/crypto';
import { turnstileCookie } from '@/utils/global';
import { setCookie } from 'cookies-next';
import React from 'react'
import Turnstile from 'react-turnstile';

const TurnstileRecaptcha = ({onVerify, className = '', ...props}) => {
  const {locale} = UseLocale()
  return (
    <Turnstile
      className={className}
      sitekey={process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY}
      onVerify={(token) => {
        setCookie(turnstileCookie, encrypt(token));
        const x = encrypt({store: 'ar', key: 'gabr'})
        console.log('recaptcha-------->', x, decrypt(x));
        onVerify(token);
      }}
      language={locale}
      {...props}
    />
  );
}

export default TurnstileRecaptcha