import { cartModalReducer, initialState } from '@/context/cart/reducers';
import { createContext, useReducer, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

const Footer = dynamic(() => import('./Footer'), {
  ssr: false,
});
import Header from './Header';
import Navbar from './Navbar';
import FixedBox from './FixedBox';
import Categories from './Categories';
import CartModal from '@/components/modals/CartModal';
import { mergeGuestCart } from '@/utils/MergeGuestCart';
import { useCart } from '@/context/cart';
import { getSession, signOut, useSession } from 'next-auth/react';
import { useCategoryTree } from '@/hooks/category/useCategoryTree';
import AuthModal from '@/components/modals/AuthModal';
import FeaturesFooter from '@/components/global/swiper/Features';
import dynamic from 'next/dynamic';
import {
  event_global_variables,
  // event_global_variables,
  // event_payment_failed,
  event_purchase,
} from '@/utils/gtm';
// import { useProductsRatings } from '@/hooks/product/useProductsRatings';
import { deleteCookie, getCookie } from 'cookies-next';
import {
  decodeJwt,
  getErrorMessage,
  handleAddToActiveCart,
} from '@/utils/global';
import { useRouter } from 'next/router';
import { getOrderByID } from '@/services/success';
import Head from 'next/head';
import store from 'store';
import TopHeader from './TopHeader';
import FloatingWhatsapp from './FloatingWhatsapp';
import { useCheckout } from '@/context/checkout';
import toast from 'react-hot-toast';
import usePreventScrollOnBack from '@/hooks/usePreventScrollOnBack';
import SearchBx from './SearchBx';
import { SWRConfig } from 'swr';

export const CartModalContext = createContext();

const Layout = ({ children }) => {
  usePreventScrollOnBack();
  const {
    t,
    i18n: { language },
  } = useTranslation('common');
  const [state, dispatch] = useReducer(cartModalReducer, initialState);
  const ourSearchContainer = useRef(null);
  const ourSearchInput = useRef(null);
  const ourSearchButton = useRef(null);
  const { dispatch: dispatchCart } = useCart();
  const { dispatch: dispatchCheckout } = useCheckout();
  const { data: session } = useSession();
  const [auth, setAuth] = useState(false);
  const [inputValue, setInputValue] = useState('');
  // const [run_global_variables, setRun_global_variables] = useState(true);
  const { push, query, asPath, locale } = useRouter();
  const handshake = getCookie('handshake');
  const mage = getCookie('mage');

  useEffect(() => {
    function isCookieNumber(cookie) {
      const pattern = /^\d+$/;
      return pattern.test(cookie);
    }

    const handleSessionExpiration = async () => {
      const currentSession = await getSession();
      const quote = getCookie('quote');
      const currentTime = Math.floor(Date.now() / 1000);
      if (mage && decodeJwt(mage)?.exp < currentTime) {
        deleteCookie('mage');
        isCookieNumber(quote) && deleteCookie('quote');
        currentSession?.user && signOut({ callbackUrl: '/' });
        dispatchCart({ type: 'clear' });
        dispatchCheckout({ type: 'clear' });
      }

      event_global_variables(
        locale,
        currentSession?.user?.id,
        currentSession?.user?.email,
        currentSession?.user?.custom_attributes
          ?.find((item) => item?.attribute_code == 'mobilenumber')
          ?.value?.slice(2),
        currentSession?.user?.city && currentSession?.user?.city
      );
    };

    handleSessionExpiration();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, mage]);

  useEffect(() => {
    if (
      (query?.success == 'false' || query?.success == false) &&
      query?.order_id
    ) {
      handleAddToActiveCart(handshake, query?.order_id, push, locale);

      const errorMessage = getErrorMessage(
        query?.payment,
        query?.reason,
        locale
      );
      toast.error(errorMessage, { position: 'top-center' }); // Required by Tabby
    }
  }, [
    handshake,
    push,
    query?.order_id,
    query?.success,
    locale,
    query?.payment,
    query?.reason,
  ]);

  const eventEventPurchaseRef = useRef(false);
  const event_purchase_is_added = store.get('event_purchase');

  useEffect(() => {
    if (
      !eventEventPurchaseRef.current &&
      (query?.success == 'true' || query?.success == true) &&
      query?.order_id
    ) {
      const handle_event_purchase = async (handshake, order_id) => {
        const res = await getOrderByID(handshake, order_id);

        const order = res?.data?.data;
        console.log('order?.items//////////', order?.items);
        if (order) {
          store.set('event_purchase', 'event purchase added');
          event_purchase(
            order?.increment_id,
            order?.items,
            order?.base_tax_amount,
            order?.grand_total,
            order?.base_shipping_amount,
            order?.qty_ordered,
            order?.payment?.additional_information?.at(0),
            getCookie('coupon_code') || order?.coupon_code
          );
        }
      };
      setTimeout(() => {
        event_purchase_is_added != 'event purchase added' &&
          handle_event_purchase(handshake, query?.order_id);
      }, 5000);

      eventEventPurchaseRef.current = true; // Mark as called after first execution
    } else {
      store.remove('event_purchase');
    }
  }, [handshake, query?.order_id, query?.success, event_purchase_is_added]);

  // const eventPaymentFailedRef = useRef(false);

  // useEffect(() => {
  //   if (!eventPaymentFailedRef.current && (query?.success == "false" || query?.success == false) && query?.reason && query?.order_id) {
  //     const handle_event_payment_failed = async (handshake, order_id) => {

  //       const res = await getOrderByID(handshake, order_id)

  //       const order = res?.data?.data

  //       event_payment_failed(Array.isArray(order?.payment?.additional_information) ? order?.payment?.additional_information?.[0]: order?.payment?.additional_information, query?.reason)
  //     }
  //     handle_event_payment_failed(handshake, query?.order_id)
  //     eventPaymentFailedRef.current = true; // Mark as called after first execution
  //   }
  // }, [handshake, query?.order_id, query?.reason, query?.success]);

  // merge guest cart to user cart
  useEffect(() => {
    mergeGuestCart(session, dispatchCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);
  console.log('session//////////', session == null);
  // useEffect(() => {
  //   const prepareData = async () => {
  //     const session = await getSession();

  //     event_global_variables(
  //       locale,
  //       session?.user?.id,
  //       session?.user?.email,
  //       session?.user?.custom_attributes
  //         ?.find((item) => item?.attribute_code == 'mobilenumber')
  //         ?.value?.slice(2),
  //       session?.user?.city && session?.user?.city
  //     );
  //   };

  //   // setTimeout(() => {
  //   // }, 2000)
  //   prepareData();
  // }, [locale]);

  // const [isChangeLang, setIsChangeLang] = useState(true);
  useCategoryTree();
  // isChangeLang, setIsChangeLang
  const ratings = null;

  const urlWithoutParams = asPath.includes('?')
    ? asPath?.slice(0, asPath.indexOf('?'))
    : asPath;

  return (
    <SWRConfig value={{ provider: () => new Map() }}>
      <CartModalContext.Provider value={{ state, dispatch }}>
        <Head>
          <link
            rel='canonical'
            href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
              locale.includes('ar') ? '' : '/en'
            }${urlWithoutParams === '/' ? '' : urlWithoutParams}`}
          />
        </Head>
        {/* TODO remove false to show top header section */}
        {false && <TopHeader />}
        <Header t={t} />
        <Navbar
          {...{
            setInputValue,
            inputValue,
            setAuth,
            t,
            ourSearchInput,
            ourSearchButton,
          }}
        />
        <Categories t={t} language={language} isSearching={inputValue != ''} />

        <div className=' mx-auto min-h-[50vh] w-11/12 max-w-[1440px] max-sm:w-[97%]'>
          <div ref={ourSearchContainer}>
            {inputValue?.length > 2 ? (
                <SearchBx
                  {...{
                    setInputValue,
                    language,
                    t,
                    ratings,
                    inputValue,
                    setInputValue,
                    ourSearchContainer,
                    ourSearchInput,
                    ourSearchButton,
                  }}
                />
            ) : (
              children
            )}
          </div>
        </div>

        <FeaturesFooter t={t} language={language} />
        <Footer t={t} setAuth={setAuth} />
        <FixedBox t={t} />
        {state?.isOpen ? (
          <CartModal {...{ state, dispatch, t, language }} />
        ) : null}
        <AuthModal {...{ auth, setAuth, t }} />
        <FloatingWhatsapp />
      </CartModalContext.Provider>
    </SWRConfig>
  );
};

export default Layout;
