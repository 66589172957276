import { gethandshakeApiRoute } from '@/services/handshake/v2';
import axios from 'axios';
import { getCookie } from 'cookies-next';
const axiosRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASEURL,
  maxBodyLength: Infinity,
  headers: {
    'Content-Type': 'application/json',
    ...(process.env.NEXT_PUBLIC_NODE_ENV === 'local' ? { 'v4tech-alawani': '1'} : {} )
  },
});
axiosRequest.interceptors.request.use(
  async (config) => {
    if(typeof window === 'object'){
      const token = getCookie('handshake');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }else{
        const locale = window.location.pathname.split('/')[1];
        const token = await gethandshakeApiRoute(locale)
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
)

axiosRequest.interceptors.response.use(
  (res) => {
    return Promise.resolve({ data: res.data, error: null, status: res.status });
  },
  (error) => {
    if (error.response) {
      // Handle HTTP errors
      // console.log('=====> http error', error);

      return Promise.reject({
        data: null,
        error: error.response?.data,
        status: error.response.status,
      });
    } else if (error.request) {
      // Handle network errors
      // console.log('=====> network error', error);
      return Promise.reject({
        data: null,
        error: error.request?.data,
        status: error.request.status,
      });
    } else {
      // Handle other errors
      // console.log('=====> error', error);
      return Promise.reject({
        data: null,
        error: error,
        status: 500,
      });
    }
  }
);

export default axiosRequest;
