import selectedAtteributes from '@/constants/selectedAttrubites';
import axiosRequest from '@/utils/axios-config';
/*
===============================|> All Search api APis <|===============================
01 |--> search Api
02 |--> save search term
===============================|> All Search api APis <|===============================
*/
// 01- =============> get products by category id
// * sortBy can be position | price
// * sortDir can be ASC | DESC
const productsSearch = async (
  handshake,
  searchWord,
  sortKeys = '',
  pageNo = 0,
  filterKeys = '',
  getFullResponse = true
) => {
  const url = `/search/products/${searchWord}?pageSize=30&pageNo=${pageNo}&${sortKeys}${filterKeys?.startsWith('&') ? filterKeys : filterKeys ? '&' + filterKeys : ''}`;
  console.log('url in productsSearch', url);
  const config = {
    method: 'get',
    url,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponse) {
    config.url += `&${selectedAtteributes}&select=highlight`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
// 02- =============> save search term
const saveSearchTerm = async (handshake, searchWord) => {
  const config = {
    method: 'get',
    url: `/search/terms/save?q=${encodeURIComponent(searchWord)}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { productsSearch, saveSearchTerm };
