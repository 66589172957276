import { getProductsByCategoryId } from '@/services/category';
import { productsSearch } from '@/services/search';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';
import useClearPLPProductsCache from './useClearCachePLPProducts';

const useSortProducts = ({
  setAllSortingKeys,
  setAllProducts,
  categoryID,
  setMoreProductsIsLoading,
  allSortingKeys,
  allFilterKeys,
  fromCategory = true,
  setPageIndex,
  word = '',
  brand,
}) => {
  const { locale } = useRouter();
  const handshake = getCookie('handshake');
  const { clearPLPProductsCache } = useClearPLPProductsCache(categoryID)
  
  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=0&categoryID=${categoryID}&${allSortingKeys}`
      : word
        ? `/api/search?word=${word}&pageNo=0&pageSize=30&${allSortingKeys}&${allFilterKeys}`
        : null,
    async (_, { arg }) => {
      return fromCategory
        ? await getProductsByCategoryId(
            handshake,
            categoryID,
            arg?.sortKeys,
            arg?.pageSize,
            arg?.pageNo,
            false,
            arg?.filterKeys,
            brand
          )
        : await productsSearch(
            handshake,
            arg?.word,
            arg?.sortKeys,
            arg?.pageNo,
            arg?.filterKeys,
            false
          );
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  const handleSorting = useCallback(
    async ({ keys }) => {
      setAllSortingKeys(keys);
      setPageIndex(1);
      clearPLPProductsCache()
      let resResult = await res?.trigger(
        fromCategory
          ? {
              pageNo: 0,
              pageSize: 30,
              categoryID,
              locale,
              filterKeys: allFilterKeys,
              sortKeys: keys,
            }
          : {
              pageNo: 0,
              pageSize: 30,
              filterKeys: allFilterKeys,
              sortKeys: keys,
              word,
            }
      );

      const result = resResult?.data?.data || {};

      setAllProducts(
        result?.products?.length && result?.products != undefined
          ? result?.products
          : []
      );
      setMoreProductsIsLoading && setMoreProductsIsLoading(false); // to getting more products after scrolling
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilterKeys, categoryID, res, setAllProducts, setAllSortingKeys, word]
  );

  return {
    handleSorting,
  };
};

export default useSortProducts;
