import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import toast from 'react-hot-toast';
// import Eye from '@/components/icons/Eye';
// import Eyelock from '@/components/icons/Eyelock';
// import { getSession, signIn } from 'next-auth/react';
import { getCookie, setCookie } from 'cookies-next';
import { loginValidation } from '@/utils/valitation/auth';
// import { event_login } from '@/utils/gtm';
import { loginByEmail, loginByMobile } from '@/services/auth';
import { useRouter } from 'next/router';
import Image from '@/components/global/image';
import userStore from '@/hooks/useStore';
import { useTurnstile } from 'react-turnstile';
import TurnstileRecaptcha from '@/components/turnstile';

// const toastMsg = (mobile, email, store, isMail) => {
//   const mobileNumber = mobile
//     ? (store === 'uae' ? '00971' : '00966').concat(mobile?.slice(-9))
//     : null;
//   return {
//     uae: {
//       ar: 'تم إزسال رمز التحقق على بريدك الالكتروني '.concat(email || ''),
//       en: 'OTP has been sent on your email address '.concat(email || ''),
//     },
//     ksa: isMail
//       ? {
//           ar: 'تم إزسال رمز التحقق على بريدك الالكتروني '.concat(email || ''),
//           en: 'OTP has been sent on your email address '.concat(email || ''),
//         }
//       : {
//           ar:
//             'تم إرسال رمز التحقق على رقم هاتفك المحمول ' + (mobileNumber || ''),
//           en: 'OTP has been sent on your mobile number ' + (mobileNumber || ''),
//         },
//   };
// };

const Login = ({ t, setAuth, setIsFromLogin }) => {
  // const [show, setShow] = useState(false);
    const turnstile = useTurnstile();

  const [mail, setMail] = useState(true);
  const handshake = getCookie('handshake');
  const { locale } = useRouter();
  const store = userStore(locale);

  const handleLogin = async (values, {setSubmitting, setFieldError, setFieldValue }) => {
    setSubmitting(true);
    if (mail) {
      const res = await loginByEmail(handshake, values.username, values.token);
      console.log('res in login', res);
      if (res?.data && res?.status === 200) {
        setIsFromLogin('by email');
        setAuth('Otp');
        toast.success(res?.data?.data?.successmsg);
        setCookie('username', values.username);
        setCookie('mail', mail);
        return setCookie('email', values.username);
      }
      turnstile?.reset();
      setFieldValue('recaptcha', false);
      setFieldValue('token', '');
      toast.error(t(res?.error?.error || res?.error?.errors?.message));
    } else {
      const { data, status, error } = await loginByMobile(
        handshake,
        values.mobilenumber,
        locale,
        values.token
      );
      console.log('res in handleLogin', {
        data,
        error,
        status,
        success: data?.data?.success,
        successmsg: data?.data?.successmsg,
      });
      if (
        status == 200 &&
        String(data?.data?.success) == 'true' &&
        data?.data?.successmsg
      ) {
        console.log('res in handleLogin____2', { data, error, status });
        setCookie('mobilenumber', values.mobilenumber);
        setCookie('mail', mail);

        toast.success(data?.data?.successmsg);
        setIsFromLogin('by mobile');
        setAuth('Otp');
      } else {
        turnstile?.reset();
         setFieldValue('recaptcha', false);
         setFieldValue('token', '');
        setFieldError(
          'mobilenumber',
          locale?.toLowerCase()?.includes('en')
            ? 'This mobile number is not registered.'
            : 'رقم الهاتف المحمول هذا غير مسجل.'
        );
      }
      console.log('res by mobile', { data, error, status });
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className='my-4 text-sm text-center sm:text-lg'>
        <span className='font-bold'>{t('If you have an account,')} </span>
        <span className='text-zinc-500'>
          {t(mail ? 'Log in using your email.' : 'Log in using your mobile.')}
        </span>
      </div>
      {store === 'ksa' && (
        <div className='grid grid-cols-2 mt-2.5 mb-4 text-sm text-center sm:text-lg'>
          <div
            onClick={() => {
              setMail(true);
              turnstile?.reset();
            }}
            className='pb-3 text-sm border-b cursor-pointer sm:text-lg'
          >
            <span
              className={`${mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
            >
              {t('Login with email')}
            </span>
          </div>
          <div
            onClick={() => {
              setMail(false);
              turnstile?.reset();
            }}
            className='pb-3 text-sm border-b cursor-pointer sm:text-lg'
          >
            <span
              className={`${!mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
            >
              {t('Login with mobile')}
            </span>
          </div>
        </div>
      )}
      <Formik
        initialValues={{
          mobilenumber: '',
          username: '',
          token: '',
          recaptcha: process.env.NEXT_PUBLIC_NODE_ENV === 'local',
        }}
        onSubmit={handleLogin}
        validationSchema={loginValidation(t, !mail, locale)}
      >
        {({ setFieldValue, isSubmitting }) => (
          <>
            {mail ? (
              <Form className='*:mb-2.5'>
                <div className='*:mb-1'>
                  <Field
                    name='username'
                    type='email'
                    className='p-1.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm sm:text-lg'
                    placeholder={t('Email Address')}
                  />
                  <ErrorMessage
                    name='username'
                    component='p'
                    className='text-sm text-red-500 sm:text-base'
                  />
                </div>
                {/* <div>
              <div className='relative *:mb-1'>
                <Field
                  name='password'
                  type={!show ? 'password' : 'text'}
                  className='p-1.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm sm:text-lg'
                  placeholder={t('Password')}
                />
                {show ? (
                  <Eye
                    onClick={() => setShow(!show)}
                    className='absolute top-1/2 z-20 -translate-y-1/2 end-2 size-4 text-zinc-500'
                  />
                ) : (
                  <Eyelock
                    onClick={() => setShow(!show)}
                    className='absolute top-1/2 z-20 -translate-y-1/2 end-2 size-4 text-zinc-500'
                  />
                )}
              </div>
              <ErrorMessage
                name='password'
                component='p'
                className='text-sm text-red-500 sm:text-base'
              />
            </div> */}
                <TurnstileRecaptcha
                  action='mobile-login-otp'
                  className='flex justify-center items-center p-2'
                  onVerify={(value) => {
                    setFieldValue('recaptcha', true);
                    setFieldValue('token', value);
                  }}
                />
                <div className='flex justify-between items-center'>
                  <div className='flex gap-1 items-center'>
                    <input
                      type='checkbox'
                      id='remember-me'
                      className='rounded-sm border appearance-none size-4 checked:border-black checked:bg-black'
                    />
                    <label
                      htmlFor='remember-me'
                      className='text-sm tlgzinc-600 sm:text-base'
                    >
                      {t('Remember Me')}
                    </label>
                  </div>
                  {/* <button
                onClick={() => {
                  setAuth('Forgot Password');
                  setIsFromLogin('by email');
                }}
                type='button'
                className='text-base font-bold underline w-fit max-sm:text-xs'
              >
                {t('Forgot Password?')}
              </button> */}
                </div>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='flex gap-2 justify-center items-center p-1.5 py-2 w-full text-lg font-bold text-white bg-black rounded-sm disabled:cursor-not-allowed disabled:opacity-75 max-sm:text-sm'
                >
                  {isSubmitting && (
                    <span className='rounded-full border-t border-r border-white animate-spin size-4' />
                  )}

                  <span>{t('Login')}</span>
                </button>
              </Form>
            ) : (
              store === 'ksa' && (
                <Form className='*:mb-2.5'>
                  <div className='*:mb-1'>
                    <div
                      className={`flex ${locale.includes('ar') ? 'flex-row' : 'flex-row-reverse'} items-center rounded-sm border p-1.5`}
                    >
                      <Field
                        name='mobilenumber'
                        className='flex-1 outline-none ltr ps-4 max-sm:placeholder:text-sm sm:text-lg'
                        maxLength={10}
                        type='tel'
                        placeholder='05xxxxxxxx'
                      />
                      <span
                        dir='ltr'
                        className='flex gap-2 justify-center items-center w-16 border-s'
                      >
                        <Image
                          loading='lazy'
                          src={
                            store === 'ksa'
                              ? '/imgs/layout/countries/sa.svg'
                              : '/imgs/layout/countries/uae.svg'
                          }
                          className='object-contain w-5'
                          alt={store === 'ksa' ? 'KSA' : 'UAE'}
                        />
                        <span>{store === 'ksa' ? '+966' : '+971'}</span>
                      </span>
                    </div>
                    <ErrorMessage
                      name='mobilenumber'
                      component='p'
                      className='text-sm text-red-500 sm:text-base'
                    />
                  </div>
                  <span className='block px-2 py-1 text-xs text-center text-yellow-600 bg-yellow-50 rounded-md'>
                    {locale === 'ar'
                      ? 'تسجيل الدخول بالجوال متاح فقط داخل السعودية . يمكن لعملاء دول الخليج تسجيل الدخول بالايميل'
                      : 'Mobile login is only available within Saudi Arabia, otherwise you can log in via email'}
                  </span>
                  <TurnstileRecaptcha
                    className='flex justify-center items-center p-2'
                    action='email-login-otp'
                    onVerify={(value) => {
                      setFieldValue('token', value);
                      setFieldValue('recaptcha', true);
                    }}
                  />
                  <div className='flex justify-between items-center'>
                    <div className='flex gap-1 items-center'>
                      <input
                        type='checkbox'
                        id='remember-me'
                        className='rounded-sm border appearance-none size-4 checked:border-black checked:bg-black'
                      />
                      <label
                        htmlFor='remember-me'
                        className='text-sm tlgzinc-600 sm:text-base'
                      >
                        {t('Remember Me')}
                      </label>
                    </div>
                    {/* <button
                onClick={() => {
                  setAuth('Forgot Password');
                  setIsFromLogin('by mobile');
                }}
                type='button'
                className='text-base font-bold underline w-fit max-sm:text-xs'
              >
                {t('Forgot Password?')}
              </button> */}
                  </div>

                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='flex justify-center items-center p-1.5 w-full h-8 font-bold text-white bg-black rounded-sm texlg disabled:cursor-not-allowed sm:text-base'
                  >
                    {isSubmitting ? (
                      <span className='rounded-full border-t border-r border-white animate-spin size-4' />
                    ) : (
                      t('Login')
                    )}
                  </button>
                </Form>
              )
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default Login;
