import { useSWRConfig } from 'swr';

const useClearPLPProductsCache = (categoryID) => {
  const { cache } = useSWRConfig();
  const cachedKeys = cache.keys();

  const clearPLPProductsCache = () => {
    for (const currCachedKey of cachedKeys) {
      if (
        currCachedKey?.includes('/api/category/pages/getMoreProducts') && currCachedKey?.includes(categoryID)
      ) {
        cache.delete(currCachedKey);
      }
    }
  }
  return {clearPLPProductsCache}
}

export default useClearPLPProductsCache