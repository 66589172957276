import { getProductsByCategoryId } from '@/services/category';
import { productsSearch } from '@/services/search';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

const useInfiniteProducts = ({
  products,
  categoryID,
  fromCategory = true,
  word = '',
  pageSize = 30,
  brand,
}) => {
  const [allProducts, setAllProducts] = useState(products || []);

  const [pageIndex, setPageIndex] = useState(1);
  const [allFilterKeys, setAllFilterKeys] = useState('');
  const [allSortingKeys, setAllSortingKeys] = useState('');
  const [moreProductsIsLoading, setMoreProductsIsLoading] = useState(false);
  const [isProductsSettedInCache, setIsProductsSettedInCache] = useState(false);
  const { locale, events,
    // asPath
  } = useRouter();
  const { cache } = useSWRConfig();
  const cachedKeys = cache.keys();
  const handshake = getCookie('handshake');
  const url_cach =
  fromCategory && !word
    ? `/api/category/pages/getMoreProducts?pageIdx=${pageIndex}&categoryID=${categoryID}&${allSortingKeys}&${allFilterKeys}`
      : `/api/search?word=${word}&pageNo=${pageIndex}&pageSize=${pageSize}&${allSortingKeys}&${allFilterKeys}`;
  
  const res = useSWRMutation(
    fromCategory
      ? `/api/category/pages/getMoreProducts?pageIdx=${pageIndex}&categoryID=${categoryID}&${allSortingKeys}&${allFilterKeys}`
      : word
        ? `/api/search?word=${word}&pageNo=${pageIndex}&pageSize=${pageSize}&${allSortingKeys}&${allFilterKeys}`
        : null,
    async (_, { arg }) => {
      return fromCategory
        ? await getProductsByCategoryId(
            handshake,
            categoryID,
            arg?.sortKeys,
            arg?.pageSize,
            arg?.pageNo,
            false,
            arg?.filterKeys,
            brand
          )
        : await productsSearch(
            handshake,
            arg?.word,
            arg?.sortKeys,
            arg?.pageNo,
            arg?.filterKeys,
            false
          );
    },
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      populateCache: true,
    }
  );

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      setAllProducts(products || []);
      setPageIndex(1);
      setAllFilterKeys('');
      setAllSortingKeys('');
      setMoreProductsIsLoading(false);
      setIsProductsSettedInCache(false);
    })
  }, [products, events]);

  const handleGettingProductsAndCaching = useCallback(
    async () => {
      setMoreProductsIsLoading(true);
      setPageIndex((prev) => prev + 1);
      const objPayload = fromCategory
        ? {
            pageNo: isProductsSettedInCache ? pageIndex + 1 : pageIndex,
            pageSize: pageSize,
            categoryID,
            locale,
            filterKeys: allFilterKeys,
            sortKeys: allSortingKeys,
          }
        : word
          ? {
              pageNo: pageIndex,
              pageSize: pageSize,
              word,
              filterKeys: allFilterKeys,
              sortKeys: allSortingKeys,
            }
          : null;

      let resResult = objPayload ? await res?.trigger(objPayload) : {};
      
      const result = resResult?.data?.data || {}

      result?.products?.length &&
        result?.products != undefined &&
        setAllProducts((prev) => [...prev, ...result?.products]);

      if (result?.products != undefined && Array.isArray(result?.products)) {
        if (result?.products?.length < pageSize) {
          setMoreProductsIsLoading(true);
        } else {
          setMoreProductsIsLoading(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilterKeys, allSortingKeys, categoryID, pageIndex, res, word, isProductsSettedInCache]
  );

  useEffect(() => {
    let index = 1;
    let updateArr = [];

    for (const currCachedKey of cachedKeys) {
      if (
        currCachedKey?.includes('/api/category/pages/getMoreProducts') && currCachedKey?.includes(categoryID) && !word
      ) {
        const selectedData = cache.get(currCachedKey)?.data?.data?.data;
        const selectedProducts = selectedData?.products;
        index = selectedData?.currentPage;
        updateArr = [...updateArr, ...(selectedProducts?.length ? selectedProducts : [])]
      }
    }

    if (products?.length && updateArr?.length && index) {
      setAllProducts([...products, ...updateArr]);
      setPageIndex(index);
      setMoreProductsIsLoading(false);
      setIsProductsSettedInCache(true);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cache,
    categoryID,
    // cachedKeys,
    // asPath,
    // asPath, productsData, cachingKey
  ]);
  
  useEffect(() => {
    if (!moreProductsIsLoading && !res?.isMutating) {
      const target = document.getElementById('afterProductsSection');
      
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            console.log('url_cach -------- ', url_cach);
            console.log('cache -------- ', cache);
            (!word ? !cache.get(url_cach) || isProductsSettedInCache : true) &&
              handleGettingProductsAndCaching(url_cach);
          }
        },
        { threshold: 1.0 }
      );
      target && observer.observe(target);

      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageIndex,
    moreProductsIsLoading,
    cache,
    res,
    categoryID,
    handleGettingProductsAndCaching,
    allSortingKeys,
    allFilterKeys,
    word,
    url_cach,
  ]);

  return {
    allProducts,
    setAllProducts,
    setAllFilterKeys,
    allFilterKeys,
    allSortingKeys,
    setAllSortingKeys,
    setPageIndex,
    setMoreProductsIsLoading,
  };
};

export default useInfiniteProducts;
