import { useRef } from 'react';
import { useRouter } from 'next/router';

import Link from 'next/link';
import Arrow from '@/components/icons/Arrow';
import useOnClickOutsideV2 from '@/hooks/global/useOnClickOutsideV2';

export default function ExpandableMenu({
  menuDataL1,
  childrenKey,
  show,
  setShow,
  isHomePage,
  btnRef,
}) {
  const { locale, push } = useRouter();
  const ref = useRef();
  useOnClickOutsideV2([ref, btnRef], () => (isHomePage ? {} : setShow(false)));

  return (
    <ul
      ref={ref}
      className={`space-y-1 ${isHomePage || show ? 'block' : 'hidden'} gray-scroll absolute -right-[0px] top-full z-50 max-h-[685px] min-w-60 overflow-y-auto bg-white p-1`}
    >
      {menuDataL1?.map((item, i) => {
        const childrenData = item[childrenKey];
        return (
          <li key={`menu-data-1-${i}`} className='border-b-[1px]'>
            <details className='group'>
              <summary className='flex items-center justify-between rounded-none px-2 text-black hover:bg-gray-100 hover:text-gray-700'>
                <span
                  onClick={() => push(`/${item?.url_key}`)}
                  className={`text-sm ${childrenData?.length === 0 ? 'font-bold' : 'font-normal'} flex h-8 cursor-pointer items-center justify-center`}
                >
                  {' '}
                  {item?.name}{' '}
                </span>
                {childrenData?.length > 0 && (
                  <span
                    className={`shrink-0 cursor-pointer transition duration-300 ${locale === 'en' ? 'group-open:rotate-90' : 'group-open:-rotate-90'}`}
                  >
                    <Arrow
                      className={locale === 'en' ? '-rotate-90' : 'rotate-90'}
                    />
                  </span>
                )}
              </summary>
              <ul className='px-4'>
                {item[childrenKey].length > 0 &&
                  item[childrenKey].map(
                    (child, i) =>
                      child?.is_active &&
                      child?.include_in_menu &&
                      child?.product_count > 0 && (
                        <li key={`menu-data-2-${i}`}>
                          <Link
                            prefetch={false}
                            href={`/${item?.url_key}/${child?.url_key}`}
                            className='block rounded-none py-2 text-sm text-black hover:bg-gray-100 hover:text-gray-700'
                          >
                            {child.name}
                          </Link>
                        </li>
                      )
                  )}
              </ul>
            </details>
          </li>
        );
      })}
    </ul>
  );
}
