import { useGlobalData } from '@/context/global';
import { useEffect, useRef } from 'react';
import useSWR from 'swr';
import store from 'store';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { getCategoryTree } from '@/services/category';

export function useCategoryTree() {
  const { locale } = useRouter();
  const { dispatch } = useGlobalData();
  const handshake = getCookie('handshake');
  const hasFetched = useRef(false);

  const shouldFetch = () => {
    const appData = store.get('app_data')?.categoryData;
    const cacheExpired = !appData?.cacheTime || appData.cacheTime <= Date.now();
    const localeMismatch = locale !== appData?.locale;
    return !appData || cacheExpired || localeMismatch;
  };

  const { mutate } = useSWR(
    () => (shouldFetch() && !hasFetched.current ? `/api/category/tree?locale=${locale}` : null),
    async () => {
      hasFetched.current = true;
      return await getCategoryTree(handshake);
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      onSuccess: (data) => {
        dispatch({
          type: 'GET_CAT_TREE',
          payload: {
            ...(data?.data?.data || {}),
            cacheTime: Date.now() + 30 * 60 * 1000,
            locale,
          },
        });
        store.set('app_data', { categoryData: { ...data?.data?.data, cacheTime: Date.now() + 30 * 60 * 1000, locale } });
        hasFetched.current = false;
      },
    }
  );

  useEffect(() => {
    if (shouldFetch() && !hasFetched.current) {
      hasFetched.current = true;
      mutate();
    } else {
      dispatch({
        type: 'GET_CAT_TREE',
        payload: store.get('app_data')?.categoryData,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, mutate, dispatch]);

  return null;
}
