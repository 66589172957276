import selectedAtteributes from '@/constants/selectedAttrubites';
import axiosRequest from '@/utils/axios-config';

const getProductBySku = async (handshake, sku) => {
  const config = {
    method: 'get',
    url: `/search/product/${sku}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  console.log('config.url ------- ', config.url);
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    console.log('getProductBySku ==================>>', error);
    return error;
  }
};

const getProductsBySkus = async (handshake, skuBulk, getFullResponse) => {
  const transformSKus = skuBulk
    ? skuBulk.map((sku) => 'sku=' + sku).join('&')
    : '';

  const config = {
    method: 'get',
    url: '/search/product?' + transformSKus,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (!getFullResponse) {
    config.url += `&${selectedAtteributes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getAmastyProducts = async (handshake, sku) => {
  const config = {
    method: 'get',
    url: `/customer/amasty_mostviewed_related_products?sku=${sku}&limit=${5}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    console.log('getAmastyProducts ==================>>', req);
    return req;
  } catch (error) {
    console.log('getAmastyProducts ==================>>', error);
    return error;
  }
};

const getProductsReviews = async (handshake, sku, selectedAtteributes) => {
  const config = {
    method: 'get',
    url: '/product/reviews/' + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => 'select=' + select)
      .join('&');
    config.url += `&${atterbiutes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getBranches = async (handshake) => {
  const config = {
    method: 'get',
    url: '/mstore/store-locator',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { getBranches };

const getAvailableBranches = async (handshake, sku) => {
  const config = {
    method: 'get',
    url: '/mstore/store-locator/' + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getAvailableStoresForSku = async (handshake, sku) => {
  const config = {
    method: 'get',
    url: '/mstore/store-locator/sku/' + sku,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export {
  getProductBySku,
  getProductsBySkus,
  getProductsReviews,
  getAvailableBranches,
  getAmastyProducts,
  getAvailableStoresForSku,
};
